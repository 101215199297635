import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getGatewayList } from 'store/reducers/gatewayReducer';

export const useGateways = () => {
  const dispatch = useAppDispatch();
  const isLoaded = useRef(false);
  const userEmail = useAppSelector(state => state.user.email);
  const gatewayList = useAppSelector(state => state.gateways);

  const refreshGatewayList = () => {
    dispatch(getGatewayList());
  };

  useEffect(() => {
    if (isLoaded.current || !userEmail) return;
    isLoaded.current = true;

    dispatch(getGatewayList());
  }, [dispatch, userEmail]);

  return {
    gatewayList,
    refreshGatewayList,
  };
};
