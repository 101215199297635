import React from 'react';
import { useAppSelector } from 'store/hooks';
import { ErrorMessage } from '@hookform/error-message';
import { useAppTranslation } from 'i18n/useAppTranslation';
import {
  Stack,
  OutlinedInput,
  Divider,
  InputLabel,
  InputAdornment,
  Box,
  useTheme,
} from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useLoginPage } from './LoginPage.hook';
import * as UI from 'UI';
import * as SC from 'SC';

export const LoginPage = () => {
  const { t } = useAppTranslation();
  const spinner = useAppSelector(state => state.spinner);
  const theme = useTheme();
  const { errors, onSubmit, passwordRegisterProps, usernameRegisterProps } =
    useLoginPage();

  return (
    <SC.AuthFormContainer>
      <Stack gap={2} sx={{ minWidth: '400px' }}>
        <SC.AuthTitle sx={SC.AuthTitleSx}>{t('Log in')}</SC.AuthTitle>
        <Stack direction={'row'} justifyContent={'center'} gap={1}>
          {t("Don't have an account?")}
          <SC.AuthLink to="/register">{t('Sign up')}</SC.AuthLink>
        </Stack>
        <Divider />
        <form onSubmit={onSubmit} className="mui-form">
          <Stack gap={2}>
            <Stack alignItems={'flex-start'}>
              <InputLabel htmlFor="email">{t('Enter E-mail')}</InputLabel>
              <OutlinedInput
                id="email"
                type="text"
                autoComplete="new-username"
                {...usernameRegisterProps}
                sx={{ alignSelf: 'stretch' }}
                error={!!errors?.username}
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircleOutlinedIcon
                      sx={{
                        color: errors?.username
                          ? theme.palette.error.main
                          : theme.palette.text.primary,
                      }}
                    />
                  </InputAdornment>
                }
              />
              <ErrorMessage
                errors={errors}
                name="username"
                render={({ message }) => <UI.ErrorString text={message} />}
              />
            </Stack>
            <Stack alignItems={'flex-start'}>
              <InputLabel htmlFor="password">{t('Enter Password')}</InputLabel>
              <OutlinedInput
                id="password"
                type="password"
                autoComplete="new-password"
                {...passwordRegisterProps}
                sx={{ alignSelf: 'stretch' }}
                startAdornment={
                  <InputAdornment position="start">
                    <LockOutlinedIcon
                      sx={{
                        color: theme.palette.text.primary,
                      }}
                    />
                  </InputAdornment>
                }
              />
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => <UI.ErrorString text={message} />}
              />
            </Stack>
            <Stack direction={'row'} justifyContent={'flex-end'} gap={2}>
              <SC.AuthLink to="/forgot">{t('Forgot password?')}</SC.AuthLink>
            </Stack>
            <SC.ControlButton
              type="submit"
              variant="contained"
              disabled={!!spinner}
            >
              {t('Log in')}
            </SC.ControlButton>
          </Stack>
        </form>
      </Stack>
      <Box sx={{ height: '10vh' }}></Box>
    </SC.AuthFormContainer>
  );
};
