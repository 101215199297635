import React from 'react';
import {
  Stack,
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
} from '@mui/material';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { useDashboardPage } from './DashboardPage.hook';
import { AddNewGatewayDialog } from 'components/AddNewGatewayDialog';
import { NewInvitesWarning } from 'components/NewInvitesWarning';
import * as SC from './DashboardPage.style';
import * as UI from 'UI';

export const DashboardPage = () => {
  const { t } = useAppTranslation();
  const theme = useTheme();
  const {
    isAddNewDialogOpened,
    openAddNewDialog,
    closeAddNewDialog,
    openDetails,
    openWebViewWithCookies,
    assignedGateways,
    gatewaysWithInvites,
    currentUser,
    currnetViewUrl,
    closeWebView,
    refreshGatewayList,
  } = useDashboardPage();

  return (
    <SC.Container>
      <Stack flexDirection="column" gap={3}>
        {!!gatewaysWithInvites?.length && (
          <NewInvitesWarning
            gatewaysWithInvites={gatewaysWithInvites}
            currentUser={currentUser}
            refreshList={refreshGatewayList}
          />
        )}
        <SC.TitleHolder>
          <SC.Title sx={SC.TitleSx}>{t('My Pytronic Homecore List')}</SC.Title>
          {/* <Button onClick={() => navigate('/view')}>Open Device Url</Button> */}
          <Stack direction={'row'} gap={1}>
            <SC.ControlButton variant="outlined" onClick={openAddNewDialog}>
              {t('Add Pytronic Homecore')}
            </SC.ControlButton>
          </Stack>
        </SC.TitleHolder>
        {assignedGateways?.map(
          ({ serialNumber, online = false, assignments }) => {
            const guestNumber = assignments.filter(
              ({ userMail }) => userMail !== currentUser
            ).length;

            const gatewayName = assignments.reduce(
              (acc, { gatewayName, userMail }) =>
                userMail !== currentUser ? gatewayName : acc,
              ''
            );

            /** @type {import("types/commonTypes").GatewayUserRole} */
            const deviceUserRole = assignments.reduce(
              (acc, { deviceUserRole, userMail }) =>
                userMail === currentUser ? deviceUserRole : acc,
              'Guest'
            );

            return (
              <SC.GridContainer container spacing={1} key={serialNumber}>
                <Grid item xs={1}>
                  <UI.DeviceIcon
                    color={
                      online
                        ? theme.palette.text.primary
                        : theme.palette.grey[400]
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <SC.NameHolder>
                    <Box>
                      <SC.PropertyLabel>{t('Name')}:</SC.PropertyLabel>
                    </Box>
                    <Box>
                      {gatewayName
                        ? `${gatewayName} (${serialNumber})`
                        : serialNumber}
                    </Box>
                  </SC.NameHolder>
                </Grid>
                <Grid item xs={2}>
                  <SC.PropertyHolder>
                    <Box>
                      <SC.PropertyLabel>{t('Shared with')}:</SC.PropertyLabel>
                    </Box>
                    <Box>
                      {guestNumber} {guestNumber === 1 ? t('User') : t('Users')}
                    </Box>
                  </SC.PropertyHolder>
                </Grid>
                <Grid item xs={3}>
                  <SC.PropertyHolder>
                    <Box>
                      <SC.PropertyLabel>{t('Access')}:</SC.PropertyLabel>
                    </Box>
                    <Box>{t(deviceUserRole)}</Box>
                  </SC.PropertyHolder>
                </Grid>
                <Grid item xs={4}>
                  <Stack
                    flexDirection={'row'}
                    gap={1}
                    justifyContent={'flex-end'}
                    alignItems={'center'}
                    flexWrap={'wrap'}
                  >
                    <SC.DetailsButton
                      variant="outlined"
                      onClick={() => openDetails(serialNumber)}
                    >
                      {t('Device Details')}
                    </SC.DetailsButton>
                    <SC.OpenButton
                      variant="contained"
                      onClick={() => openWebViewWithCookies(serialNumber)}
                      disabled={!online}
                    >
                      {t('Open')}
                    </SC.OpenButton>
                  </Stack>
                </Grid>
              </SC.GridContainer>
            );
          }
        )}
      </Stack>
      <AddNewGatewayDialog
        open={isAddNewDialogOpened}
        handleClose={closeAddNewDialog}
        refreshList={refreshGatewayList}
      />

      <Dialog
        open={!!currnetViewUrl}
        sx={{ width: '90%' }}
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle>
          Web View <button onClick={closeWebView}>X</button>
        </DialogTitle>
        <DialogContent>
          <iframe
            title="Device Web View"
            src={currnetViewUrl}
            width="800"
            height="600"
          ></iframe>
        </DialogContent>
      </Dialog>
    </SC.Container>
  );
};
