import React, { useEffect, useState } from 'react';
import { GlobalContext } from 'store/globalContext';
import { App } from 'routers/App';
import { Auth } from 'routers/Auth';
import { Find } from 'routers/Find';
import { useConfirmationsWithCallback } from 'hooks/useConfirmationWithCallback';
import { useWarnings } from 'hooks/useWarnings';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { localStorageNameEnum } from 'models/constants';
import { restoreAuth } from 'store/reducers/userReducer';
import { ModalSpinner } from 'components/ModalSpinner';
import * as FP from 'utils/fp-js';
import { CreatePassword } from 'routers/CreatePassword';

const APP_LOADING_DELAY_IN_MS = 700;

export const AuthGate = () => {
  const dispatch = useAppDispatch();
  const confirmationWithCallbackProps = useConfirmationsWithCallback();
  const warningProps = useWarnings();
  const userMail = useAppSelector(state => state.user.email);
  const status = useAppSelector(state => state.user.status);
  const [isStarting, setIsStarting] = useState(true);

  const [registrationData, setRegistrationData] = useState({
    email: '',
    password: '',
    password_repeat: '',
  });

  useEffect(() => {
    const refreshToken = window.localStorage.getItem(
      localStorageNameEnum.AUTH_REFRESH_TOKEN
    );

    if (refreshToken) {
      dispatch(restoreAuth(refreshToken));
    }
    const timeout = setTimeout(
      () => setIsStarting(false),
      APP_LOADING_DELAY_IN_MS
    );

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isStarting) {
    return <ModalSpinner open />;
  }

  const subdomain = window.location.host.split('.')[0];

  if (subdomain?.startsWith('find') || subdomain === '192') return <Find />;

  return (
    <GlobalContext.Provider
      value={{
        ...confirmationWithCallbackProps,
        ...warningProps,
        registrationData,
        setRegistrationData,
      }}
    >
      {FP.match(true)
        .on(status === 'PASS_SET_REQUIRED', <CreatePassword />)
        .otherwise(userMail ? <App /> : <Auth />)}
    </GlobalContext.Provider>
  );
};
