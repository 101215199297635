import React, { useState } from 'react';
import { Stack, Button, Box, IconButton } from '@mui/material';
import CookieIcon from '@mui/icons-material/Cookie';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { localStorageNameEnum } from 'models/constants';
import * as SC from './CookieConfirmation.style';
import CloseIcon from '@mui/icons-material/Close';

export const CookieConfirmation = () => {
  const { t } = useAppTranslation();
  const [isCookieConfirmed, setIsCookieConfirmed] = useState(
    localStorage.getItem(localStorageNameEnum.COOKIES_ARE_CONFIRMED) === 'true'
  );

  const handleConfirmCookies = () => {
    window.localStorage.setItem(
      localStorageNameEnum.COOKIES_ARE_CONFIRMED,
      'true'
    );
    setIsCookieConfirmed(true);
  };

  if (isCookieConfirmed) {
    return null;
  }

  return (
    <SC.Container elevation={7}>
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        height={'inherit'}
        minHeight={'inherit'}
        gap={3}
        width={'100%'}
      >
        <SC.ImageHolder>
          <CookieOutlinedIcon sx={{ fontSize: '4em' }} />
        </SC.ImageHolder>
        <Box flex={1}>
          {t(
            'Our website uses cookies. By continuing to navigate, we assume your permission to deploy cookies as detailed in our'
          )}
          &nbsp;
          <SC.AuthLink to="/terms">{t('Privacy Policy')}</SC.AuthLink>.
        </Box>
        <SC.ButtonHolder>
          <Button
            startIcon={<CookieIcon />}
            variant="contained"
            onClick={handleConfirmCookies}
          >
            {t('I agree')}
          </Button>
        </SC.ButtonHolder>
      </Stack>
      <Stack
        alignItems={'flex-end'}
        sx={{ margin: 0, position: 'absolute', top: 0, right: 0 }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setIsCookieConfirmed(true)}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
      </Stack>
    </SC.Container>
  );
};
