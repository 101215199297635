import {
  createAction,
  createAsyncThunk,
  createReducer,
} from '@reduxjs/toolkit';
import { hideError, showError } from './errorReducer';
import { hideSpinner, showSpinner } from './spinnerReducer';
import { statusMessages } from 'api/httpService';
import { logoutAction, restoreAuth } from './userReducer';
import { localStorageNameEnum } from 'models/constants';

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @type {import('types/commonTypes').Gateway[]} */
const initialState = null;

export const setGatewayList = createAction(
  'gateways/set',
  /**
   * @type {PayloadPreparator<import('types/commonTypes').Gateway[]>}
   */
  gateways => ({ payload: gateways })
);

export const resetGatewayList = createAction('gateways/reset');

export const getGatewayList = createAsyncThunk(
  'gateways/getGatewayList',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(showSpinner());
    thunkAPI.dispatch(hideError());

    try {
      // @ts-ignore
      const result = await thunkAPI.extra.gatewayApi.getGatewayList();
      if (result?.error === statusMessages[401]) {
        const refreshToken = window.localStorage.getItem(
          localStorageNameEnum.AUTH_REFRESH_TOKEN
        );

        if (refreshToken) {
          thunkAPI.dispatch(restoreAuth(refreshToken));
        } else {
          thunkAPI.dispatch(logoutAction());
        }
        return result;
      }

      if (result?.error) {
        thunkAPI.dispatch(showError(result.error));

        return Promise.reject();
      }

      return result;
    } catch (e) {
      thunkAPI.dispatch(showError(e.error));

      return Promise.reject();
    } finally {
      thunkAPI.dispatch(hideSpinner());
    }
  }
);

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(setGatewayList, (state, { payload }) => {
      return payload;
    })

    .addCase(resetGatewayList, () => initialState)

    .addCase(getGatewayList.fulfilled, (state, { payload }) => {
      return payload.value;
    })

    .addCase(getGatewayList.rejected, (state, { payload }) => {
      return [];
    });
});

export default reducer;
