import React from 'react';
import { FormControl, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useAppTranslation } from 'i18n/useAppTranslation';

/**
 * @param {{
 *  label?: string;
 *  name: string;
 *  control: any;
 *  rules?: any;
 *  defaultValue?: string;
 *  type?: string;
 *  size?: "small" | "medium";
 *  [key: string]: any;
 * }} props
 */
export const FormTextField = ({
  label,
  name,
  control,
  rules = {},
  defaultValue = '',
  type = 'text',
  size = 'small',
  ...props
}) => {
  const { t } = useAppTranslation();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { ref, value, ...inputProps },
        fieldState: { error },
      }) => (
        <FormControl fullWidth error={!!error}>
          <TextField
            value={value ?? ''}
            size={size}
            type={type}
            label={label}
            error={!!error}
            // @ts-ignore
            helperText={t(error?.message)}
            inputRef={ref}
            {...inputProps}
            {...props}
            variant={props.disabled ? 'filled' : 'outlined'}
          />
        </FormControl>
      )}
    />
  );
};
