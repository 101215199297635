import { createAction, createReducer } from '@reduxjs/toolkit';

/** @typedef {import("types/i18n.types").TranslationKeys} TranslationKeys */

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @type {{ message?: TranslationKeys; }} */
const initialState = {};

export const showError = createAction(
  'error/showError',
  /**
   * @type {PayloadPreparator<TranslationKeys>}
   */
  message => ({ payload: message })
);

export const hideError = createAction('error/hideError');

const reducer = createReducer(initialState, builder => {
  builder.addCase(showError, (state, { payload }) => {
    state.message = payload;
  });

  builder.addCase(hideError, () => initialState);
});

export default reducer;
