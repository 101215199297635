import React from 'react';
import CheckEmailImage from 'assets/img/check_email.svg';
import { Box, Stack } from '@mui/material';

export const CheckEmailWarning = ({ title, description }) => {
  return (
    <Stack
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ width: '100%', height: '80%' }}
      gap={3}
    >
      <img src={CheckEmailImage} width="80px" alt="check email icon" />
      <h2>{title}</h2>
      <Box sx={{ textAlign: 'center', maxWidth: '475px' }}>{description}</Box>
    </Stack>
  );
};
