import { Box, Stack, styled } from '@mui/material';

export const Container = styled(Stack)`
  flex: 1 0 auto;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 720px;
  justify-content: space-between;
  align-items: center;
  background-color: #eef1f4;
  overflow-y: auto;
`;

export const UpperBox = styled(Box)`
  flex: 0 0 auto;
  width: 100%;
  height: 26px;
  background-color: #232323;
`;

export const ContentWrapper = styled(Stack)`
  padding: 0 100px;
  width: calc(100% - 200px);
  max-width: 1280px;
`;

export const PageHeader = styled(Box)`
  margin-left: 10px;
  font-weight: bold;
  user-select: none;
`;

export const PageHeaderSx = {
  fontSize: {
    lg: 30,
    md: 20,
    sm: 15,
    xs: 15,
  },
};

export const Logo = styled('img')`
  width: 93px;
  height: 54px;
`;

export const MenuBar = styled(Stack)`
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.4em;
  margin-bottom: 0.4em;
`;

export const OutletContainer = styled(Box)`
  flex: 1 1 auto;
  margin: 30px 100px 40px 100px;
  padding: 20px;
  width: calc(100% - 240px);
  max-width: 1240px;
  background-color: white;
  border-radius: 20px;
`;

export const Footer = styled(Stack)`
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: rgba(40, 74, 112, 0.6);

  height: 77px;
  font-size: 1rem;
  background-color: #bcc9d64a;
`;
