import { Select, styled } from '@mui/material';

export const Flag = styled('img')`
  margin-top: 8px;
  margin-left: 10px;
`;

export const Selector = styled(Select)`
  width: 75px;
`;
