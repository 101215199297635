import React from 'react';
import {
  createBrowserRouter,
  Link,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import { Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import * as SC from 'components/CommonLayout.style';
import { FindPage } from 'pages/FindPage';
import { CookieConfirmation } from 'components/CookieConfirmation';
import { MenuLink } from 'components/MenuLink';
import { ModalSpinner } from 'components/ModalSpinner';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { useAppSelector } from 'store/hooks';
import PytronicLogo from 'assets/img/pytronic_logo_bw.png';
import { LanguageSelector } from 'components/LanguageSelector';
import { TermsPage } from 'pages/TermsPage';

const CommonLayout = () => {
  const { t } = useAppTranslation();
  const { pathname } = useLocation();
  const spinner = useAppSelector(state => state.spinner);

  return (
    <SC.Container>
      <SC.UpperBox />
      <Stack sx={{ margin: '0 100px' }}>
        <ModalSpinner open={spinner} />
        <SC.MenuBar>
          <Stack direction={'row'} alignItems={'center'}>
            <Link to="https://pytronic.com">
              <SC.Logo src={PytronicLogo} alt="pytronic logo" />
            </Link>
            <SC.PageHeader sx={SC.PageHeaderSx}>
              {t('Find Pytronic')}
            </SC.PageHeader>
          </Stack>
          <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            alignItems={'center'}
          >
            <MenuLink
              label={t('Find Pytronic')}
              to={'/homecore_list'}
              pathname={pathname}
              StartIcon={SearchIcon}
            />
            <MenuLink
              label={t('Remote Access')}
              to={`https://${process.env.REACT_APP_WEB_VIEW_SUBDOMAIN}`}
              pathname={pathname}
              StartIcon={FilterDramaIcon}
            />
            <LanguageSelector />
          </Stack>
        </SC.MenuBar>
      </Stack>
      <CookieConfirmation />
      <SC.OutletContainer>
        <Outlet />
      </SC.OutletContainer>
      <SC.Footer>Copyright © All rights reserved. 2023 Pytronic</SC.Footer>
    </SC.Container>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <CommonLayout />,
    children: [
      {
        path: '/homecore_list',
        element: <FindPage />,
      },
      {
        path: '/terms',
        element: <TermsPage />,
      },
      {
        path: '/',
        element: <Navigate to={'/homecore_list'} />,
      },
      {
        path: '*',
        element: <Navigate to={'/homecore_list'} />,
      },
    ],
  },
]);

export const Find = () => <RouterProvider router={router} />;
