import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Stack,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { useUnrestrictedEffect } from 'hooks/useUnrestrictedEffect';
import { getGatewayConnectionToken } from 'api/gatewayApi';
import { useApiRequest } from 'api/useApiRequest';
import { hideError } from 'store/reducers/errorReducer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import * as FP from 'utils/fp-js';

export const WebViewWithoutSerialNumber = () => {
  const dispatch = useAppDispatch();
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { callApiRequest } = useApiRequest();

  const [isBackButtonVisible, setIsBackButtonVisible] = useState(false);
  const [sessionToken, setSessionToken] = useState('');

  const serialNumber = useAppSelector(state => state.webview);

  const removeDialogOpacity = e => {
    const element = e?.target;

    if (!element) return;

    element.style.backgroundColor = 'white';
  };

  useUnrestrictedEffect({
    loadFn: () => {
      const getToken = () => {
        FP.asyncEither({
          value: callApiRequest({
            apiRequest: getGatewayConnectionToken,
            params: serialNumber,
            skipErrorHandling: true,
          }),
          leftFn: console.error,
          rightFn: value => {
            if (value?.token) {
              setSessionToken(value?.token);
              dispatch(hideError());
              return;
            }
            navigate('/device_is_not_available');
          },
        });
      };

      if (!sessionToken) {
        getToken();
      }
    },
  });

  useEffect(() => {
    if (sessionToken && !isBackButtonVisible) {
      setIsBackButtonVisible(true);
    }
  }, [sessionToken, isBackButtonVisible]);

  return (
    <>
      {!sessionToken && (
        <Stack
          justifyContent={'flex-start'}
          alignItems={'center'}
          sx={{ width: '100%', height: '100vh' }}
        >
          <Box>
            <br />
            <br />
            {t('Preparing connection')}...
            <br />
            <br />
          </Box>
          <Box>
            <br />
            <br />
            <Button
              variant="contained"
              onClick={() => {
                navigate('/');
              }}
            >
              {t('Cancel')}
            </Button>
          </Box>
        </Stack>
      )}
      {sessionToken && (
        <Stack
          justifyContent={'flex-start'}
          alignItems={'center'}
          sx={{ width: '100%', height: '100vh' }}
        >
          <Box>
            <br />
            <br />
            {t('Loading the Homecore Web App')}...
            <br />
            <br />
          </Box>
          <CircularProgress />
        </Stack>
      )}
      <Modal
        open={!!sessionToken}
        componentsProps={{
          backdrop: { style: { backgroundColor: 'transparent' } },
        }}
      >
        <Box sx={{ width: '100vw', height: '100vh' }}>
          {sessionToken && (
            <iframe
              title="Homecore Webview"
              height="100%"
              width="100%"
              style={{ border: 0 }}
              src={`https://gateway.${process.env.REACT_APP_WEB_VIEW_SUBDOMAIN}/gateway/view_device/${sessionToken}`}
              onLoad={removeDialogOpacity}
            ></iframe>
          )}
          {isBackButtonVisible && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 9999,
                backgroundColor: 'rgb(255,255,255,0.8)',
                borderBottomRightRadius: '20px',
              }}
            >
              <IconButton
                size="small"
                title={t('Back to the Homecore List')}
                color="primary"
                onClick={() => navigate('/')}
              >
                <HomeIcon />
                <ArrowBackIcon />
              </IconButton>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};
