import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const ReloadGetwayViewUrl = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/`);

    setTimeout(() => (window.location.href = 'https://home.pytronic.com/view'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
