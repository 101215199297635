import { logoutAction } from 'store/reducers/rootReducer';

/** @type {import("types/confirmationTypes").ConfirmationConfig} */
export const confirmationConfig = {
  LOGOUT: {
    title: 'Confirmation needed',
    message: 'Do you really want to log out?',
    dispatchAction: logoutAction,
  },
};
