import { createAction, createReducer } from '@reduxjs/toolkit';

/** @type {boolean} */
const initialState = false;

export const showSpinner = createAction('isLoading/showSpinner');

export const hideSpinner = createAction('isLoading/hideSpinner');

const reducer = createReducer(initialState, builder => {
  builder.addCase(showSpinner, () => true);
  builder.addCase(hideSpinner, () => false);
});

export default reducer;
