import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  IconButton,
} from '@mui/material';

export const DialogWindow = ({
  open,
  title,
  content,
  actions,
  handleClose,
  withCloseButton = false,
}) => {
  return (
    <Dialog open={open} scroll="body">
      {withCloseButton && (
        <Stack
          alignItems={'flex-end'}
          sx={{ margin: 0, position: 'absolute', top: 0, right: 0 }}
        >
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Stack>
      )}
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions sx={{ marginTop: '2rem' }}>{actions}</DialogActions>
    </Dialog>
  );
};
