import React from 'react';
import { Link } from 'react-router-dom';
import { Content, Heading, NumeratedList } from './Terms.style';

export const TermsEn = () => (
  <NumeratedList>
    <li>
      <Heading>Processing of personal data</Heading>
      <Content>
        At Pytronic we value your privacy. We always process personal data
        responsibly in accordance with applicable law. Processing refers in
        principle to all forms of processing of personal data and may include
        the collection, storage, modification, use or deletion of such data.
        This policy, which applies to anyone who uses Pytronic’s services or
        products, or otherwise comes into contact with us, explains how and why
        we process your personal data and your rights under applicable privacy
        laws.
      </Content>
    </li>
    <li>
      <Heading>Personal Data Controller</Heading>
      <Content>
        Pytronic AB, organization number 559104-1107, Speditionsvägen 36, 142 50
        Skogås, Sweden is the Personal Data Controller for the company's
        processing of personal data.
      </Content>
    </li>
    <li>
      <Heading>What type of personal data is processed?</Heading>
      <Content>
        Data that you provide us with We collect and process personal data that
        you provide us with when you visit our website, enter into agreement
        with us for products and services, subscribe to our newsletter, contact
        us or use our digital channels. Data to enable us to fulfil our
        contractual obligations We collect and process personal data that is
        necessary for us to fulfil our contractual obligations. If the necessary
        personal data is not provided, we may not be able to fulfil our
        contractual obligations.
      </Content>
    </li>
    <li>
      <Heading>To what purpose do we process personal data?</Heading>
      <Content>
        We process personal data for the purpose of fulfilling our obligations
        and to be able to provide you with products and services. We process
        your personal data in order to:
        <ul>
          <li>Fulfil our contractual obligations</li>
          <li>Manage and provide our products and services</li>
          <li>Manage customer service enquiries and other matters</li>
          <li>
            Provide data and market products and services tailored to your needs
            and interests
          </li>
          <li>Fulfil our legal obligations</li>
          <li>
            Improve our products and services through customer satisfaction
            surveys and market surveys.
          </li>
        </ul>
      </Content>
    </li>
    <li>
      <Heading>How long do we store your personal data?</Heading>
      <Content>
        Your personal data is stored for as long as it is necessary for the
        purpose for which it was collected and required for us to fulfil our
        contractual obligations. We may store it longer than necessary if
        required by law or to safeguard our legal interests during, for example,
        an ongoing legal process. Your personal data is deleted when it is no
        longer relevant to the purpose for which it was collected
      </Content>
    </li>
    <li>
      <Heading>Legal grounds for processing personal data</Heading>
      <Content>
        Our processing of personal data is mainly carried out to safeguard our
        rights and to fulfil our contractual obligations. This is done on the
        legal bases of consent, contractual obligation or legitimate interest.
        Other types of processing gives us a legitimate interest in processing
        personal data, such as to send information, conduct customer surveys and
        marketing, and provide services related to our contractual obligations.
      </Content>
    </li>
    <li>
      <Heading>Who could we share your personal data with?</Heading>
      <Content>
        Who could we share your personal data with? To the extent necessary we
        share your personal data with the suppliers and partners who provide
        services on our behalf. Your personal data is mainly shared with:
        <ul>
          <li>
            Companies such as banks that assist us with our payment system
          </li>
          <li>
            Companies from which we procure goods and services to fulfil our
            contractual obligations (e.g., furniture and furnishings suppliers)
          </li>
          <li>Companies that assist us with archiving</li>
          <li>
            Companies that conduct customer surveys on our behalf, e.g.,
            customer satisfaction surveys
          </li>
          <li>
            IT services (critical operations, technical support and maintenance
            of our IT systems)
          </li>
          <li>
            Authorities. We may sometimes be obliged by law or authority
            decision to provide personal data
          </li>
        </ul>
      </Content>
    </li>
    <li>
      <Heading>How is personal data protected?</Heading>
      <Content>
        We only process personal data that we regard as being necessary to run
        our operations and only for as long as required for the intended
        purpose. To the extent possible, we limit the number of employees who
        are given access to personal data.
      </Content>
    </li>
    <li>
      <Heading>
        Your rights as laid down in the General Data Protection Regulation
        (GDPR)
      </Heading>
      <Content>
        According to GDPR, as an individual you have certain rights in the
        processing of your personal data.
        <br />
        <br />
        Correction and access to your personal data
        <br />
        When registered you may at any time request to have incorrect data
        corrected or to change the personal data you have provided. You may also
        view your personal data we have registered. This you do by requesting a
        transcript.
        <br />
        <br />
        Revoke consent with future effect
        <br />
        If we process your personal data on the legal basis of consent, you are
        entitled to revoke your continued consent for future processing at any
        time without prejudicing the legality of the prior processing.
        <br />
        <br />
        Right to object
        <br />
        You have the right to object to any processing we carry out based on
        legitimate interest. You may unsubscribe to communication dispatches at
        any time by clicking on a link in the latest dispatch.
        <br />
        <br />
        Deletion
        <br />
        Under certain circumstances you may request to have your personal data
        deleted. However, this does not apply if we are required by law to
        retain the data.
        <br />
        <br />
        Limitation
        <br />
        You also have the right to request limitations in the processing of your
        personal data, but this may lead to us not being able to fulfil our
        obligations towards you.
        <br />
        <br />
        Data portability
        <br />
        Should you wish to transfer your data from us, you have the right to
        request a copy of your personal data in a structured, machine-readable
        format. This right only concerns data that you have provided us with.
      </Content>
    </li>
    <li>
      <Heading>Cookies</Heading>
      <Content>Our website uses cookies.</Content>
    </li>
    <li>
      <Heading>Complaints concerning our processing of personal data</Heading>
      <Content>
        If you have any complaints about our processing of your personal data,
        please get in touch{' '}
        <Link to="mailto:info@pytronic.com">info@pytronic.com</Link>. If you are
        not happy with our response, you may file your complaint with the
        applicable regulatory authority, in this case the Data Inspection Board.{' '}
      </Content>
    </li>
    <li>
      <Heading>Changes to the policy</Heading>
      <Content>
        We reserve the right to make changes to our Privacy Policy. Our website
        always has the latest version. We will inform of any changes that are
        critical to the processing of personal data. Finally, thank you for
        placing your trust in us.
      </Content>
    </li>
  </NumeratedList>
);
