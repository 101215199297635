import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { emailValidation } from 'models/validations';
import { sendResetPasswordLink } from 'api/authApi';
import { useApiRequest } from 'api/useApiRequest';
import { localStorageNameEnum } from 'models/constants';
import * as FP from 'utils/fp-js';

export const usePasswordRecoveryPage = () => {
  const { callApiRequest } = useApiRequest();
  const { register, handleSubmit, formState } = useForm();
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { errors } = formState;

  const usernameRegisterProps = register('username', emailValidation);
  const onSubmit = handleSubmit(async newData => {
    const { username } = newData;
    const lang = localStorage.getItem(localStorageNameEnum.LANG) ?? 'en';

    FP.asyncEither({
      value: callApiRequest({
        apiRequest: sendResetPasswordLink,
        params: {
          username,
          lang,
        },
      }),
      rightFn: () => setIsEmailSent(true),
    });
  });

  return {
    errors,
    onSubmit,
    usernameRegisterProps,
    isEmailSent,
  };
};
