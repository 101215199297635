import { httpService } from './httpService';

/**
 *
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * Returms gateway list for current user
 *
 * @returns {HttpResult<import("types/commonTypes").Gateway[]>}
 */
export const getGatewayList = async () =>
  httpService({
    url: '/gateway/all',
    options: {
      method: 'GET',
    },
  });

/**
 * Returms gateway details by serialNumber
 *
 * @param {string} serialNumber
 * @returns {HttpResult<import("types/commonTypes").Gateway>}
 */
export const getGatewayDetailsById = async serialNumber =>
  httpService({
    url: `/gateway/${serialNumber}`,
    options: {
      method: 'GET',
    },
  });

/**
 * Returms the gateway connection token for the webview by serialNumber
 *
 * @param {string} serialNumber
 * @returns {HttpResult<import("types/commonTypes").Gateway>}
 */
export const getGatewayConnectionToken = async serialNumber =>
  httpService({
    url: `/gateway/connect/${serialNumber}`,
    options: {
      method: 'GET',
    },
  });
