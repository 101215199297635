import { httpService } from './httpService';
/**
 *
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * Returns list of Assignment for the current authorized user
 *
 * @typedef {import("types/commonTypes").AssignmentWithGateway} AssignmentWithGateway
 * @returns {HttpResult<AssignmentWithGateway[]>}
 */
export const getAssignmentList = async () =>
  httpService({
    url: '/assignment/all',
    options: {
      method: 'GET',
    },
  });

/**
 * Adds a new assignment for guest if it does not exist.
 *
 * @param {import("types/commonTypes").AssignmentActionPayload} props
 * @returns {HttpResult<AssignmentWithGateway>}
 */
export const inviteGuest = async ({
  gatewaySerialNumber,
  userMail,
  lang = 'en',
}) =>
  httpService({
    url: '/assignment/invite_guest',
    options: {
      method: 'POST',
      data: {
        gatewaySerialNumber,
        userMail,
        lang,
      },
    },
  });

/**
 * Sets the existing invite for guest as accepted if the current user is really the same as in the invite
 *
 * @param {import("types/commonTypes").DeclineAssignmentActionPayload} props
 * @returns {HttpResult<AssignmentWithGateway>}
 */
export const removeAccessForUser = async ({
  id,
  gatewaySerialNumber,
  userMail,
}) =>
  httpService({
    url: `/assignment/decline_invite/${id}`,
    options: {
      method: 'POST',
      data: {
        gatewaySerialNumber,
        userMail,
      },
    },
  });

/**
 * Removes the existing invite for guest if the current user is the owner or this guest of this gateway
 *
 * @param {import("types/commonTypes").DeclineAssignmentActionPayload} props
 * @returns {HttpResult<AssignmentWithGateway>}
 */
export const acceptInvite = async ({ id, gatewaySerialNumber, userMail }) =>
  httpService({
    url: `/assignment/accept_invite/${id}`,
    options: {
      method: 'POST',
      data: {
        gatewaySerialNumber,
        userMail,
      },
    },
  });

/**
 * Adds device assignment by serial number
 *
 * @param {import("types/commonTypes").AddGatewayOwnerPayload} props
 * @returns {HttpResult<AssignmentWithGateway>}
 */
export const assignGatewayOwner = async ({ serialNumber, macAddress }) =>
  httpService({
    url: `/assignment/assign_owner`,
    options: {
      method: 'POST',
      data: {
        serialNumber,
        macAddress,
      },
    },
  });
