import { useSelector, useDispatch } from 'react-redux';

/**
 * @typedef {import('types/stateTypes').GlobalState} GlobalState
 * @typedef {import('types/stateTypes').AppDispatch} AppDispatch
 * @typedef {import("react-redux").TypedUseSelectorHook<GlobalState>} TypedUseSelectorHook
 */

export const /** @type {() => AppDispatch} */ useAppDispatch = useDispatch;
export const /** @type {TypedUseSelectorHook} */ useAppSelector = useSelector;
