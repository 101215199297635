import React from 'react';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { Box, Grid, Stack, useTheme } from '@mui/material';
import * as SC from './DashboardPage.style';
import * as UI from 'UI';

const mockData = [
  {
    serial: 'emXX1',
    localIp: '192.168.0.7',
  },
  {
    serial: 'emXX2',
    localIp: '192.168.0.8',
  },
];

export const FindPage = () => {
  const { t } = useAppTranslation();
  const theme = useTheme();

  return (
    <SC.Container>
      <Stack flexDirection="column" gap={3}>
        <SC.TitleHolder>
          <SC.Title sx={SC.TitleSx}>{t('My local network Homecores')}</SC.Title>
          <Stack direction={'row'} gap={1}>
            <SC.ControlButton variant="outlined" onClick={() => null}>
              {t('Refresh list')}
            </SC.ControlButton>
          </Stack>
        </SC.TitleHolder>
        {mockData.map(({ serial, localIp }) => (
          <SC.GridContainer
            container
            spacing={1}
            key={serial}
            padding={'0 2rem 0 2rem'}
          >
            <Grid item xs={1}>
              <UI.DeviceIcon color={theme.palette.text.primary} />
            </Grid>
            <Grid item xs={4}>
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={1}
              >
                <Box>
                  <SC.PropertyLabel>{t('Serial Number')}:</SC.PropertyLabel>
                </Box>
                <Box>{serial}</Box>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={1}
              >
                <Box>
                  <SC.PropertyLabel>{t('Local IP')}:</SC.PropertyLabel>
                </Box>
                <Box>{localIp}</Box>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack
                flexDirection={'row'}
                gap={1}
                justifyContent={'flex-end'}
                alignItems={'center'}
                flexWrap={'wrap'}
              >
                <SC.OpenButton
                  variant="contained"
                  onClick={() => window.open(`http://${localIp}`, '_self')}
                >
                  {t('Open locally')}
                </SC.OpenButton>
              </Stack>
            </Grid>
          </SC.GridContainer>
        ))}
      </Stack>
    </SC.Container>
  );
};
