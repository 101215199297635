import { createAction, createReducer } from '@reduxjs/toolkit';

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @type {string} */
const initialState = '';

export const setDeviceSerialNumber = createAction(
  'webview/setDeviceSerialNumber',
  /**
   * @type {PayloadPreparator<string>}
   */
  serialNumber => ({ payload: serialNumber })
);

export const resetDeviceSerialNumber = createAction(
  'webview/resetDeviceSerialNumber'
);

const reducer = createReducer(initialState, builder => {
  builder.addCase(setDeviceSerialNumber, (state, { payload }) => payload);
  builder.addCase(resetDeviceSerialNumber, () => initialState);
});

export default reducer;
