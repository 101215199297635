import React from 'react';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { Stack } from '@mui/material';

export const HomecoreErrorPage = () => {
  const { t } = useAppTranslation();

  return (
    <Stack
      justifyContent={'center'}
      alignItems={'center'}
      sx={{ width: '100%', height: '60vh' }}
    >
      <h3>{t('Homecore is not available')}</h3>
    </Stack>
  );
};
