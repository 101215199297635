import React from 'react';
import { MenuItem, FormControl } from '@mui/material';
import { localStorageNameEnum } from 'models/constants';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { languageList } from 'i18n/i18n';
import * as SC from './LanguageSelector.style';
import UkFlag from 'assets/img/uk_flag.jpg';
import FrFlag from 'assets/img/fr_flag.jpg';
import SvFlag from 'assets/img/sv_flag.jpg';

const flags = {
  EN: <SC.Flag src={UkFlag} alt="UK flag icon" />,
  FR: <SC.Flag src={FrFlag} alt="FR flag icon" />,
  SV: <SC.Flag src={SvFlag} alt="SV flag icon" />,
};

export const LanguageSelector = () => {
  const { i18n } = useAppTranslation();

  const handleChangeLanguage = e => {
    i18n.changeLanguage(e?.target?.value);
    localStorage.setItem(localStorageNameEnum.LANG, e?.target?.value);
  };

  return (
    <FormControl variant="standard">
      <SC.Selector
        onChange={handleChangeLanguage}
        value={i18n.language}
        disableUnderline
      >
        {languageList.map(lang => {
          return (
            <MenuItem key={lang} value={lang} sx={{ marginLeft: '-7px' }}>
              {flags[lang.toUpperCase()]}
            </MenuItem>
          );
        })}
      </SC.Selector>
    </FormControl>
  );
};
