import React, { useContext } from 'react';
import { GlobalContext } from 'store/globalContext';
import * as UI from 'UI';
import * as CSC from 'SC';
import { useAppTranslation } from 'i18n/useAppTranslation';

export const WarningDialog = () => {
  const { t } = useAppTranslation();
  const { warning, hideWarning } = useContext(GlobalContext);
  const { title, content } = warning;

  if (!title || !content) return null;

  return (
    <UI.DialogWindow
      open={true}
      withCloseButton
      handleClose={hideWarning}
      title={title}
      content={content}
      actions={
        <CSC.DialogButton variant="contained" onClick={hideWarning}>
          {t('Got it')}
        </CSC.DialogButton>
      }
    />
  );
};
