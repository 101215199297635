export const TOKEN_CHANGE_ERROR_MESSAGE_DELAY_IN_MS = 1000;

export const SERIAL_NUMBER_LENGTH = 5;
export const MAX_ADDRESS_LENGTH = 17;

export const USERNAME_MAX_LENGTH = 250;
export const PHONE_MAX_LENGTH = 100;

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 12;

export const localStorageNameEnum = {
  LANG: 'LANG',
  AUTH_TOKEN: 'AUTH_TOKEN',
  AUTH_REFRESH_TOKEN: 'AUTH_REFRESH_TOKEN',
  COOKIES_ARE_CONFIRMED: 'COOKIES_ARE_CONFIRMED',
};
