import {
  // SERIAL_NUMBER_LENGTH,
  MAX_ADDRESS_LENGTH,
  USERNAME_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  // PASSWORD_MAX_LENGTH,
} from 'models/constants';

export const required = 'is required';

export const getSerialNumberValidation = translatedMessage => ({
  validate: {
    length: value => (value?.length && value.length > 0) || translatedMessage,
    notEnoughDigits: value => !value.match(/_/i) || 'All digits are required',
  },
});

export const getMacAddressValidation = translatedMessage => ({
  validate: {
    length: value => value?.length === MAX_ADDRESS_LENGTH || translatedMessage,
    notEnoughDigits: value => !value.match(/_/i) || translatedMessage,
  },
});

export const fullNameValidation = {
  pattern: {
    value: /^[a-z0-9\s]+$/i,
    message: 'Use letters and numbers only',
  },
  maxLength: {
    value: USERNAME_MAX_LENGTH,
    message: `Max length is ${USERNAME_MAX_LENGTH}`,
  },
};

export const phoneNumberValidation = {
  validate: value => {
    const str = value.toString();

    if (str.includes('_')) {
      return 'All digits are required';
    }
    return true;
  },
};

export const emailPattern = {
  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  //value: /^\S+@\S+$/i,
  message: 'Invalid email address',
};

export const emailValidation = {
  required,
  pattern: emailPattern,
};

export const passwordValidation = {
  required,
  validate: {
    minLength: value =>
      value.length >= PASSWORD_MIN_LENGTH ||
      `Min length is ${PASSWORD_MIN_LENGTH}`,
    // maxLength: value =>
    //   value.length <= PASSWORD_MAX_LENGTH ||
    //   `Max length is ${PASSWORD_MAX_LENGTH}`,
    // noWhiteSpaces: value =>
    //   !!value.match(RegExp('^[^\\s]*$')) || 'need no whitespaces',
    // oneLowercase: value =>
    //   !!value.match(RegExp('(.*[a-z].*)')) ||
    //   'need one lowercase character at least',
    // oneUppercase: value =>
    //   !!value.match(RegExp('(.*[A-Z].*)')) ||
    //   'need one uppercase character at least',
    // oneNumber: value =>
    //   !!value.match(RegExp('(.*\\d.*)')) ||
    //   'need one number character at least',
    // oneSpecialChar: value =>
    //   !!value.match(RegExp('[!@#$%^&*(),.?":{}|<>]')) ||
    //   'need one special character at least',
  },
};

export const getPasswordConfirmValidation = password => ({
  validate: value => value === password || 'Passwords must match',
});
