import { combineReducers, createAction, createReducer } from '@reduxjs/toolkit';
import confirmation from './confirmationReducer';
import user, { initialState as userInitialState } from './userReducer';
import error from './errorReducer';
import assignments from './assignmentsReducer';
import gateways from './gatewayReducer';
import spinner from './spinnerReducer';
import webview from './webviewReducer';

import { localStorageNameEnum } from 'models/constants';

/**
 * @template T
 * @typedef {import("types/stateTypes").PayloadPreparator<T>} PayloadPreparator
 */

const appReducer = combineReducers({
  confirmation,
  user,
  error,
  spinner,
  assignments,
  gateways,
  webview,
});

export const exampleAction = createAction(
  'root/exampleAction',
  /**
   * @type {PayloadPreparator<string>}
   */
  value => ({ payload: value })
);

export const logoutAction = createAction('root/logout');

const rootReducer = createReducer(
  /** @type {ReturnType<typeof appReducer>} */ (undefined),
  builder => {
    builder
      .addCase(exampleAction, (state, { payload }) => {
        // Just an example
        // state.prop = payload
      })
      .addCase(logoutAction, state => {
        state.error.message = undefined;
        state.user = userInitialState;
        /* TODO: Add real auth token functionality based on the backend logic */
        window.sessionStorage.removeItem(localStorageNameEnum.AUTH_TOKEN);
      });

    builder.addDefaultCase(appReducer);
  }
);

export default rootReducer;
