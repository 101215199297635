import { createTheme, responsiveFontSizes } from '@mui/material';

export const theme = responsiveFontSizes(
  createTheme({
    typography: {
      fontFamily: ['Messina Sans', 'sans-serif'].join(','),
    },
    palette: {
      primary: {
        main: 'rgba(114, 118, 168, 1)',
        dark: 'rgba(93, 96, 221, 1)',
      },
      secondary: {
        light: '#ff7961',
        main: '#F47969',
        dark: '#EE513C',
        contrastText: '#fff',
      },
      text: {
        primary: '#284a70',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 60,
            minHeight: 40,
            textTransform: 'none',
            fontSize: 14,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            textAlign: 'center',
            fontWeight: 'bold',
            padding: 0,
            fontSize: '2rem !important',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            textAlign: 'center',
            fontSize: '1.1rem',
            padding: 0,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: '1rem',
            padding: '50px 130px',
            gap: '20px',
            maxHeight: 'calc(100% - 20px)',
            maxWidth: '800px',
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            borderRadius: '16px !important',
            padding: '5px 0',
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: '#284A70',
            borderRadius: '20px',
            padding: '8px 15px',
            border: '1px solid #284A70',
          },
          arrow: {
            color: '#284A70',
          },
        },
      },
    },
  })
);
