import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'store/hooks';
import { useApiRequest } from 'api/useApiRequest';
import {
  Stack,
  IconButton,
  InputLabel,
  InputAdornment,
  Alert,
  useTheme,
  Box,
} from '@mui/material';
import LockIcon from '@mui/icons-material/LockOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAppTranslation } from 'i18n/useAppTranslation';
import * as UI from 'UI';
import * as SC from 'SC';
import { deleteAccount } from 'api/authApi';
import { logoutAction } from 'store/reducers/userReducer';
import { passwordValidation } from 'models/validations';
import * as FP from 'utils/fp-js';

const defaultFormData = {
  password: '',
};

export const DeleteAccountDialog = ({ open, handleClose }) => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { callApiRequest } = useApiRequest();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [error, setError] = useState('');
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormData,
  });

  const onSubmit = () => {
    const { password } = getValues();
    setError('');

    FP.asyncEither({
      value: callApiRequest({
        apiRequest: deleteAccount,
        params: {
          password,
        },
        skipErrorHandling: true,
      }),
      leftFn: setError,
      rightFn: () => {
        reset(defaultFormData);
        dispatch(logoutAction());
        handleClose();
        setTimeout(() => navigate(`/`));
      },
    });
  };

  const closeAndReset = () => {
    setError('');
    reset(defaultFormData);
    handleClose();
  };

  return (
    <UI.DialogWindow
      open={open}
      withCloseButton
      handleClose={closeAndReset}
      title={<>{t('Delete Account')}</>}
      content={
        <Stack gap={1}>
          <Box>
            {t(
              'If you delete your account, all your devices will be disconnected from your account and smartphone app. You can still access your devices in your home network or attach them to a different account'
            )}
          </Box>
          <SC.EmptySpace />
          <SC.Text>{t('Enter current password to delete an account')}</SC.Text>
          <Stack alignItems={'flex-start'}>
            <InputLabel htmlFor="password">
              {t('Enter current password')}
            </InputLabel>
            <UI.FormTextField
              id="password"
              name="password"
              control={control}
              rules={passwordValidation}
              type={isPasswordVisible ? 'text' : 'password'}
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon
                      sx={{
                        color: errors?.password
                          ? theme.palette.error.main
                          : theme.palette.text.primary,
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <IconButton
                    color="info"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  >
                    {isPasswordVisible ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                ),
                autoComplete: 'off',
              }}
            />
          </Stack>
          {error && <Alert severity="error">{error}</Alert>}
          <SC.EmptySpace />
        </Stack>
      }
      actions={
        <SC.ControlButton
          variant="contained"
          color="secondary"
          onClick={handleSubmit(onSubmit)}
          sx={{ padding: '5px 40px' }}
        >
          {t('Delete Account')}
        </SC.ControlButton>
      }
    />
  );
};
