import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { customColors } from 'models/customColors';

export const menuLinkSx = {
  padding: '0 15px',
  minHeight: '2.1rem',
  borderRadius: '2rem',
  color: customColors.menu.notSelected.text,
  backgroundColor: 'rgba(93, 96, 221, 0)',
  fontSize: {
    lg: 16,
    md: 14,
    sm: 10,
    xs: 7,
  },
};

export const MenuLink = ({ label, to, pathname = '', StartIcon }) => {
  const isActive = pathname === to;
  const color = isActive
    ? customColors.menu.selected.text
    : customColors.menu.notSelected.text;

  return (
    <Button
      component={Link}
      startIcon={<StartIcon color={color} />}
      to={to}
      sx={{
        ...menuLinkSx,
        color,
        backgroundColor: isActive
          ? customColors.menu.selected.background
          : customColors.menu.notSelected.background,
      }}
    >
      <Typography noWrap>{label}</Typography>
    </Button>
  );
};
