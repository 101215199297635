import { createContext } from 'react';
import { defaultConfirmationsWidthCallbackContextState } from 'hooks/useConfirmationWithCallback';

const noop = () => null;

/**
 * @typedef {import("types/confirmationTypes").ConfirmationsWithCallbackProps} ConfirmationsWithCallbackProps
 * @typedef {import("types/commonTypes").RegistrationData} RegistrationData
 */

/**
 * @type {{
 *  confirmationWithCallback: ConfirmationsWithCallbackProps;
 *  openConfirmationWithCallback: React.Dispatch<React.SetStateAction<ConfirmationsWithCallbackProps>>;
 *  closeConfirmationWithCallback: () => void;
 *  warning: any;
 *  showWarning: (props: { title: any, content: any}) => void;
 *  hideWarning: () => void;
 *  registrationData?: RegistrationData;
 *  setRegistrationData: (props: RegistrationData) => void;
 * }}
 */
export const initialContextState = {
  confirmationWithCallback: defaultConfirmationsWidthCallbackContextState,
  openConfirmationWithCallback: noop,
  closeConfirmationWithCallback: noop,
  warning: {},
  showWarning: noop,
  hideWarning: noop,
  registrationData: undefined,
  setRegistrationData: noop,
};

export const GlobalContext = createContext(initialContextState);
