const sv = {
  /** @type {import("../../types/i18n.types").Translation} */
  translation: {
    '404 Not Found': '404 Not Found',
    'A home tailored to you': 'Ett hem skräddarsytt för dig',
    Abort: 'Abortera',
    Accept: 'Acceptera',
    Accepted: 'Accepterad',
    Access: 'Tillgång',
    'Add New Homecore': 'Lägg till ny Homecore',
    'Add Pytronic Homecore': 'Lägg till Pytronic Homecore',
    'Already a member?': 'Redan medlem?',
    'Back to the Homecore List': 'Tillbaka till Homecore-listan',
    'Back to the previous page': 'Tillbaka till föregående sida',
    'Before you can log in, you need to verify your e-mail address by clicking the link in the verification message sent to the e-mail address you used to sign up.':
      'Innan du kan logga in måste du verifiera din e-postadress genom att klicka på länken i verifieringsmeddelandet som skickas till den e-postadress du använde för att registrera dig.',
    Cancel: 'Annullera',
    'Change E-mail': 'Byta e-mail',
    'Change Password': 'Ändra lösenord',
    'Check your e-mail for instructions on how to change your Pytronic ID':
      'Kontrollera din e-post för instruktioner om hur du ändrar ditt Pytronic-ID',
    'Check your e-mail for instructions on how to change your password.':
      'Kontrollera din e-post för instruktioner om hur du ändrar ditt lösenord.',
    Close: 'Stänga',
    'Confirm New Password': 'Bekräfta nytt lösenord',
    'Confirmation needed': 'Bekräftelse behövs',
    'Create new password': 'Skapa nytt lösenord',
    'Create Password': 'Skapa lösenord',
    Decline: 'Nedgång',
    'Delete Account': 'Radera konto',
    'Delete Device': 'Ta bort enhet',
    'Delete User': 'Ta bort användare',
    'Device Details': 'Enhetsdetaljer',
    'Device added successfully': 'Enheten har lagts till',
    'Device owner': 'Enhetsägare',
    'Discard & Proceed': 'Släng och fortsätt',
    'Discard Changes': 'Ignorera ändringar',
    'Do you really want to log out?': 'Vill du verkligen logga ut?',
    'Do you really want to remove access for this user?':
      'Vill du verkligen ta bort åtkomst för den här användaren?',
    "Don't have an account?": 'Har du inget konto?',
    'Email verification': 'Mejl verifikation',
    'Enter E-mail of the user you want to give access to your Homecore':
      'Ange e-postadressen för den användare du vill ge åtkomst till din Homecore',
    'Enter current password': 'Ange aktuellt lösenord',
    'Enter current password to delete an account':
      'Ange aktuellt lösenord för att radera ett konto',
    'Enter E-mail': 'Skriv in e-mail',
    'Enter Password': 'Skriv in lösenord',
    'Enter the MAC Address': 'Ange MAC-adressen',
    'Enter the Serial Number': 'Ange serienumret',
    'Enter your e-mail address. We will send you a link to reset your password':
      'Skriv in din mailadress. Vi skickar dig en länk för att återställa ditt lösenord',
    Error: 'Fel',
    'Find the information you need to add Homecore: Serial no., MAC Address.':
      'Hitta informationen du behöver för att lägga till Homecore: Serienummer, MAC-adress.',
    'Forgot password?': 'Glömt ditt lösenord?',
    'Full Name': 'Fullständiga namn',
    'General Device Information': 'Allmän enhetsinformation',
    'Go back to the Home screen': 'Gå tillbaka till startskärmen',
    'Got it': 'Jag fattar',
    Guest: 'Gäst',
    Home: 'Hem',
    'I agree': 'Jag håller med',
    'I agree with': 'Jag håller med',
    'If you delete the device, it will be disconnected from your account and smartphone app. You can still access it on your home network.':
      'Om du tar bort enheten kommer den att kopplas bort från ditt konto och smartphone-appen. Du kan fortfarande komma åt den i ditt hemnätverk',
    'If you delete your account, all your devices will be disconnected from your account and smartphone app. You can still access your devices in your home network or attach them to a different account':
      'Om du tar bort ditt konto kommer alla dina enheter att kopplas bort från ditt konto och din smartphone-app. Du kan fortfarande komma åt dina enheter i ditt hemnätverk eller koppla dem till ett annat konto.',
    'Instructions sent': 'Instruktioner skickade',
    'Invalid email address': 'Ogiltig e-postadress',
    'Invalid Home Center data. Please check them out':
      'Ogiltig Home Center-data. Vänligen kolla in dem',
    'Invalid MAC Address. Please check them out':
      'Ogiltig MAC-adress. Vänligen kolla in dem',
    'Invite User': 'Bjud in användare',
    'Invite from': 'Bjud in från',
    'Invite sent successfully': 'Inbjudan har skickats',
    'Loading the Homecore Web App': 'Laddar Homecore Web App',
    'Log in': 'Logga in',
    'Login with error': 'Logga in med fel',
    Logout: 'Logga ut',
    'MAC Address': 'MAC-adress',
    'Manage the purchased core boxes through the admin application':
      'Hantera de köpta kärnboxarna via admin-appen',
    'My Account': 'Mitt Konto',
    'My Pytronic Homecore List': 'Min Pytronic Homecore-lista',
    Name: 'Namn',
    'New Invites': 'Nya inbjudningar',
    'Not Accepted': 'Inte accepterad',
    Open: 'Öppen',
    'Our website uses cookies. By continuing to navigate, we assume your permission to deploy cookies as detailed in our':
      'Vår webbplats använder cookies. Genom att fortsätta att navigera antar vi din tillåtelse att distribuera cookies enligt vår',
    Owner: 'Ägare',
    'Password Recovery': 'Återställning av lösenord',
    'Password change': 'Lösenordsändring',
    'Password reset': 'Lösenordsåterställning',
    'Passwords must match': 'Lösenorden måste matcha',
    'Personal Information': 'Personlig information',
    'Phone Number': 'Telefonnummer',
    'Preparing connection': 'Förbereder anslutning',
    'Privacy Policy': 'Integritetspolicy',
    Proceed: 'Fortsätt',
    'Refresh list': 'Uppdatera listan',
    Register: 'Registrera',
    Registration: 'Registrering',
    'Repeat new password': 'Upprepa nya lösenordet',
    'Save & Proceed': 'Spara och fortsätt',
    'Save Changes': 'Spara ändringar',
    Send: 'Skicka',
    'Send a link for email change': 'Skicka en länk för e-poständring',
    'Serial Number': 'Serienummer',
    Services: 'Tjänster',
    'Set New Password': 'Ange nytt lösenord',
    'Set E-mail': 'Ställ in e-post',
    'Set Password': 'Välj lösenord',
    'Set the new email that will be used for login':
      'Ställ in den nya e-postadressen som ska användas för inloggning',
    'Set the new password that will be used for login':
      'Ställ in det nya lösenordet som ska användas för inloggning',
    'Shared with': 'Delas med',
    'Sign up': 'Bli Medlem',
    'Terms of Service and Privacy Policy':
      'Användarvillkor och sekretesspolicy',
    'The MAC address is located on the back of cargo':
      'MAC-adressen finns på baksidan av lasten',
    'The serial number is located on the back of the device':
      'Serienumret finns på baksidan av enheten',
    'Unable to add device': 'Det gick inte att lägga till enhet',
    'Unsaved changes': 'Osparade ändringar',
    User: 'användare',
    Users: 'användare',
    Website: 'Hemsida',
    'Welcome to React': 'Välkommen till React and react-i18next',
    'Wrong login/password': 'Fel inloggning/lösenord',
    'You have the new invite': 'Du har den nya inbjudan',
    'You have unsaved changes there.': 'Du har osparade ändringar där.',
    'You need a password to delete your account':
      'Du behöver ett lösenord för att radera ditt konto',
    'You need the old password to set a new one':
      'Du behöver det gamla lösenordet för att ställa in ett nytt',
    /* ============================================================== */
    'My local network Homecores': 'Mitt lokala nätverk Homecores',
    'Local IP': 'Lokal IP',
    'Open locally': 'Öppet lokalt',
    'Find Pytronic': 'Hitta Pytronic',
    'Remote Access': 'Fjärranslutning',
  },
};

export default sv;
