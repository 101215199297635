export const customColors = {
  menu: {
    selected: {
      text: 'rgba(93, 96, 221, 1)',
      background: 'rgba(93, 96, 221, 0.15)',
    },
    notSelected: {
      text: '#284A70',
      background: 'rgba(93, 96, 221, 0)',
    },
  },
  text: '#284A70',
};
