import React, { useRef } from 'react';
import { useAppSelector } from 'store/hooks';
import { useAppTranslation } from 'i18n/useAppTranslation';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  getPasswordConfirmValidation,
  passwordValidation,
} from 'models/validations';
import {
  Button,
  Stack,
  Divider,
  InputLabel,
  InputAdornment,
  Box,
  IconButton,
  useTheme,
} from '@mui/material';
import { usePasswordCreatePage } from './PasswordCreatePage.hook';
import * as UI from 'UI';
import * as SC from 'SC';

export const PasswordCreatePage = () => {
  const { t } = useAppTranslation();
  const theme = useTheme();
  const spinner = useAppSelector(state => state.spinner);
  const {
    watch,
    control,
    isPasswordVisible,
    onSubmit,
    setIsPasswordVisible,
    errors,
  } = usePasswordCreatePage();

  const password = useRef('');
  password.current = watch('password', '');

  return (
    <Stack justifyContent={'center'} alignItems={'center'} height={'100%'}>
      <Stack gap={2}>
        <SC.AuthTitle sx={SC.AuthTitleSx}>{t('Set Password')}</SC.AuthTitle>
        <Stack direction={'row'} justifyContent={'center'} gap={1}>
          <SC.Text>
            {t('Set the new password that will be used for login')}
          </SC.Text>
        </Stack>
        <Divider />
        <form onSubmit={onSubmit} className="mui-form">
          <Stack gap={2}>
            <Stack alignItems={'flex-start'}>
              <InputLabel htmlFor="password">{t('Create Password')}</InputLabel>
              <UI.FormTextField
                id="password"
                name="password"
                control={control}
                rules={passwordValidation}
                type={isPasswordVisible ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon
                        sx={{
                          color: errors?.password
                            ? theme.palette.error.main
                            : theme.palette.text.primary,
                        }}
                      />
                    </InputAdornment>
                  ),
                  autoComplete: 'off',
                }}
              />
            </Stack>
            <Stack alignItems={'flex-start'}>
              <InputLabel htmlFor="password_repeat">
                {t('Confirm New Password')}
              </InputLabel>
              <UI.FormTextField
                id="password_repeat"
                name="password_repeat"
                control={control}
                rules={getPasswordConfirmValidation(password.current)}
                type={isPasswordVisible ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon
                        sx={{
                          color: errors?.password_repeat
                            ? theme.palette.error.main
                            : theme.palette.text.primary,
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      color="info"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    >
                      {isPasswordVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  ),
                  autoComplete: 'off',
                }}
              />
            </Stack>
            <Button type="submit" variant="contained" disabled={!!spinner}>
              {t('Set Password')}
            </Button>
          </Stack>
        </form>
      </Stack>
      <Box sx={{ height: '25vh' }}></Box>
    </Stack>
  );
};
