/*
 * This object is the source of indexes for translation autocomplete functionality
 * and for other languages translation objects.
 */
const en = {
  translation: {
    '404 Not Found': '404 Not Found',
    'A home tailored to you': 'A home tailored to you',
    Abort: 'Abort',
    Accept: 'Accept',
    Accepted: 'Accepted',
    Access: 'Access',
    'Add New Homecore': 'Add New Homecore',
    'Add Pytronic Homecore': 'Add Pytronic Homecore',
    'Already a member?': 'Already a member?',
    'Back to the Homecore List': 'Back to the Homecore List',
    'Back to the previous page': 'Back to the previous page',
    'Before you can log in, you need to verify your e-mail address by clicking the link in the verification message sent to the e-mail address you used to sign up.':
      'Before you can log in, you need to verify your e-mail address by clicking the link in the verification message sent to the e-mail address you used to sign up.',
    Cancel: 'Cancel',
    'Change E-mail': 'Change E-mail',
    'Change Password': 'Change Password',
    'Check your e-mail for instructions on how to change your Pytronic ID':
      'Check your e-mail for instructions on how to change your Pytronic ID',
    'Check your e-mail for instructions on how to change your password.':
      'Check your e-mail for instructions on how to change your password.',
    Close: 'Close',
    'Confirm New Password': 'Confirm New Password',
    'Confirmation needed': 'Confirmation needed',
    'Create new password': 'Create new password',
    'Create Password': 'Create Password',
    Decline: 'Decline',
    'Delete Account': 'Delete Account',
    'Delete Device': 'Delete Device',
    'Delete User': 'Delete User',
    'Device Details': 'Device Details',
    'Device added successfully': 'Device added successfully',
    'Device owner': 'Device owner',
    'Discard & Proceed': 'Discard & Proceed',
    'Discard Changes': 'Discard Changes',
    'Do you really want to log out?': 'Do you really want to log out?',
    'Do you really want to remove access for this user?':
      'Do you really want to remove access for this user?',
    "Don't have an account?": "Don't have an account?",
    'Email verification': 'Email verification',
    'Enter E-mail of the user you want to give access to your Homecore':
      'Enter E-mail of the user you want to give access to your Homecore',
    'Enter current password': 'Enter current password',
    'Enter current password to delete an account':
      'Enter current password to delete an account',
    'Enter E-mail': 'Enter E-mail',
    'Enter Password': 'Enter Password',
    'Enter the MAC Address': 'Enter the MAC Address',
    'Enter the Serial Number': 'Enter the Serial Number',
    'Enter your e-mail address. We will send you a link to reset your password':
      'Enter your e-mail address. We will send you a link to reset your password',
    Error: 'Error',
    'Find the information you need to add Homecore: Serial no., MAC Address.':
      'Find the information you need to add Homecore: Serial no., MAC Address.',
    'Forgot password?': 'Forgot password?',
    'Full Name': 'Full Name',
    'General Device Information': 'General Device Information',
    'Go back to the Home screen': 'Go back to the Home screen',
    'Got it': 'Got it',
    Guest: 'Guest',
    Home: 'Home',
    'I agree': 'I agree',
    'I agree with': 'I agree with',
    'If you delete the device, it will be disconnected from your account and smartphone app. You can still access it on your home network.':
      'If you delete the device, it will be disconnected from your account and smartphone app. You can still access it on your home network.',
    'If you delete your account, all your devices will be disconnected from your account and smartphone app. You can still access your devices in your home network or attach them to a different account':
      'If you delete your account, all your devices will be disconnected from your account and smartphone app. You can still access your devices in your home network or attach them to a different account',
    'Instructions sent': 'Instructions sent',
    'Invalid email address': 'Invalid email address',
    'Invalid Home Center data. Please check them out':
      'Invalid Home Center data. Please check them out',
    'Invalid MAC Address. Please check them out':
      'Invalid MAC Address. Please check them out',
    'Invite User': 'Invite User',
    'Invite from': 'Invite from',
    'Invite sent successfully': 'Invite sent successfully',
    'Loading the Homecore Web App': 'Loading the Homecore Web App',
    'Log in': 'Log in',
    'Login with error': 'Login with error',
    Logout: 'Logout',
    'MAC Address': 'MAC Address',
    'Manage the purchased core boxes through the admin application':
      'Manage the purchased core boxes through the admin application',
    'My Account': 'My Account',
    'My Pytronic Homecore List': 'My Pytronic Homecore List',
    Name: 'Name',
    'New Invites': 'New Invites',
    'Not Accepted': 'Not Accepted',
    Open: 'Open',
    'Our website uses cookies. By continuing to navigate, we assume your permission to deploy cookies as detailed in our':
      'Our website uses cookies. By continuing to navigate, we assume your permission to deploy cookies as detailed in our',
    Owner: 'Owner',
    'Password Recovery': 'Password Recovery',
    'Password change': 'Password change',
    'Password reset': 'Password reset',
    'Passwords must match': 'Passwords must match',
    'Personal Information': 'Personal Information',
    'Phone Number': 'Phone Number',
    'Preparing connection': 'Preparing connection',
    'Privacy Policy': 'Privacy Policy',
    Proceed: 'Proceed',
    'Refresh list': 'Refresh list',
    Register: 'Register',
    Registration: 'Registration',
    'Repeat new password': 'Repeat new password',
    'Save & Proceed': 'Save & Proceed',
    'Save Changes': 'Save Changes',
    Send: 'Send',
    'Send a link for email change': 'Send a link for email change',
    'Serial Number': 'Serial Number',
    Services: 'Services',
    'Set New Password': 'Set New Password',
    'Set E-mail': 'Set E-mail',
    'Set Password': 'Set Password',
    'Set the new email that will be used for login':
      'Set the new email that will be used for login',
    'Set the new password that will be used for login':
      'Set the new password that will be used for login',
    'Shared with': 'Shared with',
    'Sign up': 'Sign up',
    'Terms of Service and Privacy Policy':
      'Terms of Service and Privacy Policy',
    'The MAC address is located on the back of cargo':
      'The MAC address is located on the back of cargo',
    'The serial number is located on the back of the device':
      'The serial number is located on the back of the device',
    'Unable to add device': 'Unable to add device',
    'Unsaved changes': 'Unsaved changes',
    User: 'User',
    Users: 'Users',
    Website: 'Website',
    'Welcome to React': 'Welcome to React and react-i18next',
    'Wrong login/password': 'Wrong login/password',
    'You have the new invite': 'You have the new invite',
    'You have unsaved changes there.': 'You have unsaved changes there.',
    'You need a password to delete your account':
      'You need a password to delete your account',
    'You need the old password to set a new one':
      'You need the old password to set a new one',
    /* ============================================================== */
    'My local network Homecores': 'My local network Homecores',
    'Local IP': 'Local IP',
    'Open locally': 'Open locally',
    'Find Pytronic': 'Find Pytronic',
    'Remote Access': 'Remote Access',
  },
};

export default en;
