import React, { useState, useEffect } from 'react';
import { Stack, Grid, useTheme } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { useGateways } from 'hooks/useGateways';
import { useAppSelector } from 'store/hooks';
import { acceptInvite, removeAccessForUser } from 'api/assignmentApi';
import { useApiRequest } from 'api/useApiRequest';
import { ModalSpinner } from 'components/ModalSpinner';
import * as SC from 'components/NewInvitesWarning.style';
import * as UI from 'UI';
import * as FP from 'utils/fp-js';

const INVITE_VALIDITY_CHECK_TIMEOUT_MS = 3000;

export const InvitePage = () => {
  const { t } = useAppTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { callApiRequest } = useApiRequest();
  const { inviteToken } = useParams();
  const currentUser = useAppSelector(state => state.user.email);
  const [isLoading, setIsLoading] = useState(true);
  const { gatewayList } = useGateways();

  const currentDevice = gatewayList?.find(({ assignments }) =>
    assignments.reduce(
      (acc, next) => (next?.inviteToken === inviteToken ? next : acc),
      undefined
    )
  );

  const assignment = currentDevice?.assignments?.find(
    item => item?.inviteToken === inviteToken && !item.inviteAccepted
  );

  const sender = currentDevice?.assignments.reduce(
    (acc, { userMail, deviceUserRole }) =>
      deviceUserRole === 'Owner' ? userMail : acc,
    ''
  );

  const { id, gatewaySerialNumber, userMail } = assignment ?? {};

  const handleAccept = (id, serialNumber) => {
    FP.asyncEither({
      value: callApiRequest({
        apiRequest: acceptInvite,
        params: {
          id,
          gatewaySerialNumber: serialNumber,
          userMail: currentUser,
        },
      }),

      rightFn: () => navigate('/'),
    });
  };

  const handleDecline = (id, serialNumber) => {
    FP.asyncEither({
      value: callApiRequest({
        apiRequest: removeAccessForUser,
        params: {
          id,
          gatewaySerialNumber: serialNumber,
          userMail: currentUser,
        },
      }),

      rightFn: () => navigate('/'),
    });
  };

  useEffect(() => {
    const timeout = setTimeout(
      () => setIsLoading(false),
      INVITE_VALIDITY_CHECK_TIMEOUT_MS
    );
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if ((!inviteToken || !id || userMail !== currentUser) && !isLoading) {
      navigate('/');
    }
  }, [currentUser, id, inviteToken, isLoading, navigate, userMail]);

  if (!inviteToken || !id || userMail !== currentUser) {
    return <ModalSpinner open />;
  }

  return (
    <Stack
      justifyContent={'flex-start'}
      alignItems={'center'}
      sx={{ width: '100%', height: '50vh' }}
      gap={5}
    >
      <SC.Title sx={SC.TitleSx}>{t('You have the new invite')}</SC.Title>
      <SC.GridContainer container spacing={1} key={id}>
        <Grid item xs={1}>
          <UI.DeviceIcon color={theme.palette.success.main} />
        </Grid>
        <Grid item xs={2}>
          <SC.PropertyHolder>
            <SC.PropertyLabel>{t('Name')}:</SC.PropertyLabel>
            <SC.PropertyValue>{gatewaySerialNumber}</SC.PropertyValue>
          </SC.PropertyHolder>
        </Grid>
        <Grid item xs={6}>
          <SC.PropertyHolder>
            <SC.PropertyLabel>{t('Invite from')}:</SC.PropertyLabel>
            <SC.PropertyValue>{sender}</SC.PropertyValue>
          </SC.PropertyHolder>
        </Grid>
        <Grid item xs={3}>
          <Stack flexDirection={'row'} gap={1}>
            <SC.DeclineButton
              variant="outlined"
              color="success"
              onClick={() => handleDecline(id, gatewaySerialNumber)}
            >
              {t('Decline')}
            </SC.DeclineButton>
            <SC.AcceptButton
              variant="contained"
              color="success"
              onClick={() => handleAccept(id, gatewaySerialNumber)}
            >
              {t('Accept')}
            </SC.AcceptButton>
          </Stack>
        </Grid>
      </SC.GridContainer>
    </Stack>
  );
};
