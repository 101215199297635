import React, { useContext } from 'react';
import { GlobalContext } from 'store/globalContext';
import * as UI from 'UI';
import * as CSC from 'SC';
import {
  InputAdornment,
  Stack,
  IconButton,
  Box,
  InputLabel,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import InformationIcon from 'assets/img/information_icon.svg';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { useForm } from 'react-hook-form';
import {
  getMacAddressValidation,
  getSerialNumberValidation,
} from 'models/validations';
import { assignGatewayOwner } from 'api/assignmentApi';
import { useApiRequest } from 'api/useApiRequest';
import { showSpinner, hideSpinner } from 'store/reducers/spinnerReducer';
import { useAppDispatch } from 'store/hooks';
import * as FP from 'utils/fp-js';

const defaultFormData = {
  serialNumber: '',
  macAddress: '',
};

export const AddNewGatewayDialog = ({ open, handleClose, refreshList }) => {
  const { t } = useAppTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormData,
  });

  const onSubmit = () => {
    dispatch(showSpinner());
    const { serialNumber, macAddress } = getValues();
    reset(defaultFormData);
    handleClose();

    FP.asyncEither({
      value: callApiRequest({
        apiRequest: assignGatewayOwner,
        params: {
          serialNumber: serialNumber?.toUpperCase() ?? '',
          macAddress,
        },
        skipErrorHandling: true,
      }),
      leftFn: error => {
        showWarning({
          title: (
            <Stack justifyContent={'center'} alignItems={'center'} gap={3}>
              <UI.BigDeviceIcon color={theme.palette.error.main} />
              <Typography
                variant="h5"
                color={'error'}
                sx={{ fontWeight: 'bold' }}
              >
                {t('Unable to add device')}
              </Typography>
            </Stack>
          ),
          content: (
            <>
              <CSC.EmptySpace />
              <Typography color={'error'}>
                {t(
                  // @ts-ignore
                  error
                )}
              </Typography>
              <CSC.EmptySpace />
            </>
          ),
        });
        dispatch(hideSpinner());
      },
      rightFn: () => {
        showWarning({
          title: (
            <Stack justifyContent={'center'} alignItems={'center'} gap={3}>
              <UI.BigDeviceIcon color={theme.palette.text.primary} />
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {t('Device added successfully')}
              </Typography>
            </Stack>
          ),
          content: <CSC.EmptySpace />,
        });
        dispatch(hideSpinner());
        refreshList();
      },
    });
  };

  const closeAndReset = () => {
    reset(defaultFormData);
    handleClose();
  };

  return (
    <UI.DialogWindow
      open={open}
      withCloseButton
      handleClose={closeAndReset}
      title={t('Add New Homecore')}
      content={
        <>
          <form>
            <Stack gap={2} sx={{ textAlign: 'center' }}>
              <Box sx={{ maxWidth: '400px' }}>
                {t(
                  'Find the information you need to add Homecore: Serial no., MAC Address.'
                )}
              </Box>
              <Stack alignItems={'flex-start'}>
                <InputLabel htmlFor="serial-number">
                  {t('Enter the Serial Number')}
                  <Tooltip
                    arrow
                    title={t(
                      'The serial number is located on the back of the device'
                    )}
                    placement="right"
                  >
                    <IconButton
                      sx={{ padding: 0, paddingLeft: '5px' }}
                      tabIndex={-1}
                    >
                      <UI.MenuIcon src={InformationIcon} />
                    </IconButton>
                  </Tooltip>
                </InputLabel>
                <UI.FormMaskedTextField
                  id="serial-number"
                  name="serialNumber"
                  control={control}
                  rules={getSerialNumberValidation(
                    t('Invalid Home Center data. Please check them out')
                  )}
                  // eslint-disable-next-line no-useless-escape
                  // mask={'aa999'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <UI.SerialNumberIcon
                          color={
                            errors?.serialNumber
                              ? theme.palette.error.main
                              : theme.palette.text.primary
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Stack alignItems={'flex-start'}>
                <InputLabel htmlFor="mac-address">
                  {t('Enter the MAC Address')}
                  <Tooltip
                    arrow
                    title={t('The MAC address is located on the back of cargo')}
                    placement="right"
                  >
                    <IconButton
                      sx={{ padding: 0, paddingLeft: '5px' }}
                      tabIndex={-1}
                    >
                      <UI.MenuIcon src={InformationIcon} />
                    </IconButton>
                  </Tooltip>
                </InputLabel>
                <UI.FormMaskedTextField
                  id="mac-address"
                  name="macAddress"
                  control={control}
                  rules={getMacAddressValidation(
                    t('Invalid MAC Address. Please check them out')
                  )}
                  mask="**:**:**:**:**:**"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <UI.MacAddressIcon
                          color={
                            errors?.macAddress
                              ? theme.palette.error.main
                              : theme.palette.text.primary
                          }
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
          </form>
        </>
      }
      actions={
        <CSC.DialogButton variant="contained" onClick={handleSubmit(onSubmit)}>
          {t('Add New Homecore')}
        </CSC.DialogButton>
      }
    />
  );
};
