import { createAction, createReducer } from '@reduxjs/toolkit';

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @type {import("types/stateTypes").GlobalConfirmation} */
export const initialState = {
  modalConfirmationType: 'NONE',
  modalConfirmationProps: {},
};

export const openConfirmation = createAction(
  'confirmation/open',
  /**
   * @type {PayloadPreparator<import('types/stateTypes').GlobalConfirmation>}
   */
  props => ({ payload: props })
);

export const closeConfirmation = createAction('confirmation/close');

const reducer = createReducer(initialState, builder => {
  builder.addCase(openConfirmation, (state, action) => {
    const { modalConfirmationProps, modalConfirmationType } = action.payload;
    state.modalConfirmationType = modalConfirmationType;
    state.modalConfirmationProps = modalConfirmationProps;
  });

  builder.addCase(closeConfirmation, () => initialState);
});

export default reducer;
