import { useCallback, useState } from 'react';

export const useWarnings = () => {
  const [warning, setWarning] = useState({});

  const showWarning = useCallback(({ title, content }) => {
    setWarning({
      title,
      content,
    });
  }, []);

  const hideWarning = useCallback(() => {
    setWarning({});
  }, []);

  return {
    warning,
    showWarning,
    hideWarning,
  };
};
