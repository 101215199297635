import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useGateways } from 'hooks/useGateways';
import { setDeviceSerialNumber } from 'store/reducers/webviewReducer';
import { localStorageNameEnum } from 'models/constants';
import { showError } from 'store/reducers/errorReducer';
import { useAppTranslation } from 'i18n/useAppTranslation';

export const useDashboardPage = () => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { gatewayList, refreshGatewayList } = useGateways();
  const [currnetViewUrl, setCurrnetViewUrl] = useState('');
  const currentUser = useAppSelector(state => state.user.email);

  const assignedGateways = gatewayList
    ?.filter(({ serialNumber, assignments }) =>
      assignments.reduce(
        (acc, { userMail, inviteAccepted, deviceUserRole }) =>
          userMail === currentUser &&
          (inviteAccepted || deviceUserRole === 'Owner')
            ? true
            : acc,
        false
      )
    )
    .sort((a, b) => (a.serialNumber < b.serialNumber ? -1 : 1));

  const gatewaysWithInvites = gatewayList?.filter(
    ({ serialNumber, assignments }) =>
      assignments.reduce(
        (acc, { userMail, inviteAccepted, deviceUserRole }) =>
          userMail === currentUser &&
          !inviteAccepted &&
          deviceUserRole === 'Guest'
            ? true
            : acc,
        false
      )
  );

  const [isAddNewDialogOpened, setIsAddNewDialogOpened] = useState(false);

  const openAddNewDialog = () => {
    setIsAddNewDialogOpened(true);
  };

  const closeAddNewDialog = () => {
    setIsAddNewDialogOpened(false);
  };

  const openDetails = serialNumber => {
    navigate(`/details/${serialNumber}`);
  };

  const openWebViewWithCookies = async serialNumber => {
    if (serialNumber) {
      const accessToken = sessionStorage.getItem(
        localStorageNameEnum.AUTH_TOKEN
      );

      if (accessToken) {
        try {
          await fetch(
            `https://${process.env.REACT_APP_WEB_VIEW_SUBDOMAIN}/view/gateway/view_device/${serialNumber}`,
            {
              method: 'GET',
              credentials: 'include',
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          setTimeout(
            () =>
              (window.location.href = `https://${process.env.REACT_APP_WEB_VIEW_SUBDOMAIN}/view/`)
          );
        } catch (error) {
          // @ts-ignore
          dispatch(showError(t(error)));
        }
        return;
      }

      navigate('/device_is_not_available');
    }
  };

  const openWebViewInThisSubdomain = serialNumber => {
    navigate(`/app_view/${serialNumber}/`);
  };

  const openWebViewWithoutSN = serialNumber => {
    dispatch(setDeviceSerialNumber(serialNumber));
    setTimeout(() => navigate(`/app_view`));
  };

  const closeWebView = () => {
    setCurrnetViewUrl('');
  };

  return {
    isAddNewDialogOpened,
    openAddNewDialog,
    closeAddNewDialog,
    openDetails,
    openWebViewInThisSubdomain,
    openWebViewWithoutSN,
    assignedGateways,
    gatewaysWithInvites,
    currentUser,
    currnetViewUrl,
    closeWebView,
    refreshGatewayList,
    openWebViewWithCookies,
  };
};
