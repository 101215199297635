import { useEffect } from 'react';
import { confirmRegistration } from 'api/authApi';
import { useApiRequest } from 'api/useApiRequest';
import { useUnrestrictedEffect } from 'hooks/useUnrestrictedEffect';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { restoreAuth } from 'store/reducers/userReducer';
import * as FP from 'utils/fp-js';

export const ConfirmRegistrationPage = () => {
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { callApiRequest } = useApiRequest();

  useEffect(() => {}, []);

  useUnrestrictedEffect({
    loadFn: () => {
      FP.asyncEither({
        value: callApiRequest({
          apiRequest: confirmRegistration,
          params: token,
          skipErrorHandling: true,
        }),
        leftFn: () => navigate('/'),
        rightFn: value => {
          dispatch(restoreAuth(value?.refresh?.token));
        },
      });
    },
  });

  return null;
};
