import { useForm } from 'react-hook-form';
import { emailValidation } from 'models/validations';
import { useAppDispatch } from 'store/hooks';
import { getAuth } from 'store/reducers/userReducer';
import { useContext } from 'react';
import { GlobalContext } from 'store/globalContext';

export const useLoginPage = () => {
  const { hideWarning } = useContext(GlobalContext);
  const { register, handleSubmit, formState } = useForm();
  const { errors } = formState;

  const usernameRegisterProps = register('username', emailValidation);
  const passwordRegisterProps = register('password');

  const dispatch = useAppDispatch();

  const onSubmit = handleSubmit(async newData => {
    const { username, password } = newData;
    hideWarning();
    dispatch(getAuth({ username, password }));
  });

  return {
    errors,
    onSubmit,
    usernameRegisterProps,
    passwordRegisterProps,
  };
};
