import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'store/hooks';
import { useApiRequest } from 'api/useApiRequest';
import { updateAccount } from 'api/authApi';
import { restoreAuth } from 'store/reducers/userReducer';
import * as FP from 'utils/fp-js';

const defaultValues = {
  password: '',
  password_repeat: '',
};

export const usePasswordCreatePage = () => {
  const { callApiRequest } = useApiRequest();
  const dispatch = useAppDispatch();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const {
    register,
    control,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty },
    trigger,
    watch,
  } = useForm({
    defaultValues,
  });

  const onSubmit = handleSubmit(({ password }) => {
    FP.asyncEither({
      value: callApiRequest({
        apiRequest: updateAccount,
        params: {
          password,
        },
      }),
      rightFn: value => dispatch(restoreAuth(value?.refresh?.token)),
    });
  });

  return {
    onSubmit,
    isPasswordVisible,
    setIsPasswordVisible,
    errors,
    isDirty,
    trigger,
    getValues,
    setValue,
    register,
    reset,
    control,
    watch,
  };
};
