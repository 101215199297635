import React, { useRef } from 'react';
import { useAppSelector } from 'store/hooks';
import { useAppTranslation } from 'i18n/useAppTranslation';
import {
  Stack,
  Divider,
  InputLabel,
  InputAdornment,
  Box,
  IconButton,
  Checkbox,
  useTheme,
} from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useRegistrationPage } from './RegistartionPage.hook';
import * as UI from 'UI';
import * as SC from 'SC';
import {
  getPasswordConfirmValidation,
  emailValidation,
  passwordValidation,
} from 'models/validations';
import { CheckEmailWarning } from '../components/CheckEmailWarning';

export const RegistrationPage = () => {
  const { t } = useAppTranslation();
  const theme = useTheme();
  const spinner = useAppSelector(state => state.spinner);
  const {
    watch,
    control,
    errors,
    isPasswordVisible,
    onSubmit,
    setIsPasswordVisible,
    isTermsAccepted,
    setIsTermsAccepted,
    isEmailSent,
  } = useRegistrationPage();

  const password = useRef('');
  password.current = watch('password', '');

  if (isEmailSent) {
    return (
      <CheckEmailWarning
        title={t('Email verification')}
        description={t(
          'Before you can log in, you need to verify your e-mail address by clicking the link in the verification message sent to the e-mail address you used to sign up.'
        )}
      />
    );
  }

  return (
    <SC.AuthFormContainer>
      <Stack gap={2}>
        <SC.AuthTitle sx={SC.AuthTitleSx}>{t('Registration')}</SC.AuthTitle>
        <Stack direction={'row'} justifyContent={'center'} gap={1}>
          {t('Already a member?')}
          <SC.AuthLink to="/login">{t('Log in')}</SC.AuthLink>
        </Stack>
        <Divider />
        <form onSubmit={onSubmit} className="mui-form">
          <Stack gap={3}>
            <Stack alignItems={'flex-start'}>
              <InputLabel htmlFor="email">{t('Enter E-mail')}</InputLabel>
              <UI.FormTextField
                id="email"
                name="email"
                control={control}
                rules={emailValidation}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlinedIcon
                        sx={{
                          color: errors?.email
                            ? theme.palette.error.main
                            : theme.palette.text.primary,
                        }}
                      />
                    </InputAdornment>
                  ),
                  autoComplete: 'off',
                }}
              />
            </Stack>
            <Stack alignItems={'flex-start'}>
              <InputLabel htmlFor="password">{t('Create Password')}</InputLabel>
              <UI.FormTextField
                id="password"
                name="password"
                control={control}
                rules={passwordValidation}
                type={isPasswordVisible ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon
                        sx={{
                          color: errors?.password
                            ? theme.palette.error.main
                            : theme.palette.text.primary,
                        }}
                      />
                    </InputAdornment>
                  ),
                  autoComplete: 'off',
                }}
              />
            </Stack>
            <Stack alignItems={'flex-start'}>
              <InputLabel htmlFor="password_repeat">
                {t('Confirm New Password')}
              </InputLabel>
              <UI.FormTextField
                id="password_repeat"
                name="password_repeat"
                control={control}
                rules={getPasswordConfirmValidation(password.current)}
                type={isPasswordVisible ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon
                        sx={{
                          color: errors?.password_repeat
                            ? theme.palette.error.main
                            : theme.palette.text.primary,
                        }}
                      />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <IconButton
                      color="info"
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    >
                      {isPasswordVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  ),
                  autoComplete: 'off',
                }}
              />
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <Checkbox
                  inputProps={{
                    'aria-label': 'Checkbox demo',
                  }}
                  checked={isTermsAccepted}
                  onClick={() => setIsTermsAccepted(!isTermsAccepted)}
                />
                <Box>{t('I agree with')}</Box>
                <SC.AuthLink to="/terms">
                  {t('Terms of Service and Privacy Policy')}
                </SC.AuthLink>
              </Stack>
            </Stack>
            <SC.ControlButton
              type="submit"
              variant="contained"
              disabled={!!spinner || !isTermsAccepted}
            >
              {t('Register')}
            </SC.ControlButton>
          </Stack>
        </form>
      </Stack>
      <Box sx={{ height: '10vh' }}></Box>
    </SC.AuthFormContainer>
  );
};
