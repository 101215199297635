import { configureStore } from '@reduxjs/toolkit';
import reducer from 'store/reducers/rootReducer';
import * as authApi from 'api/authApi';
import * as gatewayApi from 'api/gatewayApi';
import * as assignmentApi from 'api/assignmentApi';

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: {
        extraArgument: {
          authApi,
          gatewayApi,
          assignmentApi,
        },
      },
    }),
  devTools: process.env.NODE_ENV === 'development',
  enhancers: [],
});

export const getStoreState = () => {
  return store.getState();
};

export default store;
