/* eslint-disable no-console */
import { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useApiRequest } from 'api/useApiRequest';
import { addUser } from 'api/authApi';
import { GlobalContext } from 'store/globalContext';
import { localStorageNameEnum } from 'models/constants';
import * as FP from 'utils/fp-js';

export const useRegistrationPage = () => {
  const { callApiRequest } = useApiRequest();
  const { registrationData, setRegistrationData } = useContext(GlobalContext);

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const {
    register,
    control,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty },
    trigger,
    watch,
  } = useForm({
    defaultValues: registrationData,
  });

  const onSubmit = handleSubmit(async ({ email, password }) => {
    const lang = localStorage.getItem(localStorageNameEnum.LANG) ?? 'en';
    FP.asyncEither({
      value: callApiRequest({
        apiRequest: addUser,
        params: {
          username: email,
          password,
          lang,
        },
      }),
      rightFn: () => setIsEmailSent(true),
    });
  });

  useEffect(() => {
    reset(registrationData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enteredData = watch();

  useEffect(() => {
    if (
      enteredData.email === registrationData.email &&
      enteredData.password === registrationData.password &&
      enteredData.password_repeat === registrationData.password_repeat
    ) {
      return;
    }

    setRegistrationData(enteredData);
  }, [
    enteredData,
    registrationData.email,
    registrationData.password,
    registrationData.password_repeat,
    setRegistrationData,
  ]);

  return {
    onSubmit,
    isPasswordVisible,
    setIsPasswordVisible,
    errors,
    isDirty,
    trigger,
    getValues,
    setValue,
    register,
    reset,
    control,
    watch,
    isTermsAccepted,
    setIsTermsAccepted,
    isEmailSent,
  };
};
