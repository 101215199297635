import React from 'react';
import { Stack, Grid, Divider, useTheme } from '@mui/material';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { acceptInvite, removeAccessForUser } from 'api/assignmentApi';
import { useApiRequest } from 'api/useApiRequest';
import * as SC from './NewInvitesWarning.style';
import * as UI from 'UI';
import * as FP from 'utils/fp-js';

export const NewInvitesWarning = ({
  gatewaysWithInvites,
  currentUser,
  refreshList,
}) => {
  const { t } = useAppTranslation();
  const theme = useTheme();
  const { callApiRequest } = useApiRequest();

  // const invites = gatewaysWithInvites.reduce((acc, { assignments }) => {
  //   assignments.forEach(elem => {
  //     const { userMail, deviceUserRole } = elem;
  //     if (userMail === currentUser && deviceUserRole === 'Guest') {
  //       acc.push(elem);
  //     }
  //   });

  //   return acc;
  // }, []);

  // console.log(`===== invites`, invites);

  const handleAccept = (id, serialNumber) => {
    FP.asyncEither({
      value: callApiRequest({
        apiRequest: acceptInvite,
        params: {
          id,
          gatewaySerialNumber: serialNumber,
          userMail: currentUser,
        },
      }),
      rightFn: refreshList,
    });
  };

  const handleDecline = (id, serialNumber) => {
    FP.asyncEither({
      value: callApiRequest({
        apiRequest: removeAccessForUser,
        params: {
          id,
          gatewaySerialNumber: serialNumber,
          userMail: currentUser,
        },
      }),
      rightFn: refreshList,
    });
  };

  return (
    <Stack flexDirection="column" gap={2}>
      <SC.Title sx={SC.TitleSx}>{t('New Invites')}</SC.Title>
      {gatewaysWithInvites?.map(({ serialNumber, assignments }) => {
        const sender = assignments.reduce(
          (acc, { userMail, deviceUserRole }) =>
            deviceUserRole === 'Owner' ? userMail : acc,
          ''
        );

        const id = assignments.reduce(
          (acc, { id, deviceUserRole, userMail }) =>
            userMail === currentUser && deviceUserRole === 'Guest' ? id : acc,
          ''
        );

        return (
          <SC.GridContainer
            container
            spacing={1}
            key={id}
            alignItems={'center'}
          >
            <Grid item xs={1}>
              <UI.DeviceIcon color={theme.palette.success.main} />
            </Grid>
            <Grid item xs={2}>
              <SC.PropertyHolder>
                <SC.PropertyLabel>{t('Name')}:</SC.PropertyLabel>
                <SC.PropertyValue>{serialNumber}</SC.PropertyValue>
              </SC.PropertyHolder>
            </Grid>
            <Grid item xs={6}>
              <SC.PropertyHolder>
                <SC.PropertyLabel>{t('Invite from')}:</SC.PropertyLabel>
                <SC.PropertyValue>{sender}</SC.PropertyValue>
              </SC.PropertyHolder>
            </Grid>
            <Grid item xs={3}>
              <Stack flexDirection={'row'} gap={1}>
                <SC.DeclineButton
                  variant="outlined"
                  color="success"
                  onClick={() => handleDecline(id, serialNumber)}
                >
                  {t('Decline')}
                </SC.DeclineButton>
                <SC.AcceptButton
                  variant="contained"
                  color="success"
                  onClick={() => handleAccept(id, serialNumber)}
                >
                  {t('Accept')}
                </SC.AcceptButton>
              </Stack>
            </Grid>
          </SC.GridContainer>
        );
      })}
      <Divider />
    </Stack>
  );
};
