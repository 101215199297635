import { Box, Stack, styled, Grid, Button, Typography } from '@mui/material';

export const Title = styled(Box)`
  margin: 0;
  font-weight: bold;
  color: rgba(40, 74, 112, 1);
  user-select: none;
`;

export const TitleSx = {
  fontSize: {
    lg: 30,
    md: 20,
    sm: 15,
    xs: 10,
  },
};

export const GridContainer = styled(Grid)`
  align-items: center;
  margin: 0 10px;
  width: calc(100% - 20px);
`;

export const DeclineButton = styled(Button)`
  flex: 1 0 auto;
`;

export const AcceptButton = styled(Button)`
  flex: 1 1 auto;
`;

export const PropertyHolder = styled(Stack)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  color: #242731;
`;

export const PropertyLabel = styled(Typography)`
  color: #8a8d97;
`;

export const PropertyValue = styled(Box)`
  max-width: 100%;
  word-wrap: break-word;
`;
