import { Box, Paper, styled } from '@mui/material';

export { AuthLink } from 'SC';

export const Container = styled(Paper)`
  position: fixed;
  right: 3vw;
  left: 3vw;
  bottom: 3vh;
  width: calc(100% - 6vw);
  height: 15vh;
  min-height: 80px;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: white;
  border-radius: 20px;
  z-index: 1000;
`;

export const ImageHolder = styled(Box)`
  padding-left: 40px;
`;

export const ButtonHolder = styled(Box)`
  padding-right: 40px;
`;
