import React from 'react';
import { useAppSelector } from 'store/hooks';
import { ErrorMessage } from '@hookform/error-message';
import { useAppTranslation } from 'i18n/useAppTranslation';
import {
  Stack,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Box,
  useTheme,
} from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { usePasswordRecoveryPage } from './PasswordRecoveryPage.hook';
import * as UI from 'UI';
import * as SC from 'SC';
import { CheckEmailWarning } from '../components/CheckEmailWarning';

export const PasswordRecoveryPage = () => {
  const { t } = useAppTranslation();
  const theme = useTheme();
  const spinner = useAppSelector(state => state.spinner);
  const { errors, onSubmit, usernameRegisterProps, isEmailSent } =
    usePasswordRecoveryPage();

  if (isEmailSent) {
    return (
      <CheckEmailWarning
        title={t('Password reset')}
        description={t(
          'Check your e-mail for instructions on how to change your password.'
        )}
      />
    );
  }

  return (
    <SC.AuthFormContainer>
      <Stack gap={2}>
        <SC.AuthTitle sx={SC.AuthTitleSx}>
          {t('Password Recovery')}
        </SC.AuthTitle>
        <Stack direction={'row'} justifyContent={'center'} gap={1}>
          <Box sx={{ maxWidth: '381px', textAlign: 'center' }}>
            {t(
              'Enter your e-mail address. We will send you a link to reset your password'
            )}
          </Box>
        </Stack>
        <form onSubmit={onSubmit} className="mui-form">
          <Stack gap={4}>
            <Stack alignItems={'flex-start'}>
              <InputLabel htmlFor="email">{t('Enter E-mail')}</InputLabel>
              <OutlinedInput
                id="email"
                type="text"
                error={!!errors?.username}
                autoComplete="off"
                {...usernameRegisterProps}
                sx={{ alignSelf: 'stretch' }}
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircleOutlinedIcon
                      sx={{
                        color: errors?.username
                          ? theme.palette.error.main
                          : theme.palette.text.primary,
                      }}
                    />
                  </InputAdornment>
                }
              />
              <ErrorMessage
                errors={errors}
                name="username"
                render={({ message }) => <UI.ErrorString text={message} />}
              />
            </Stack>
            <SC.ControlButton
              type="submit"
              variant="contained"
              disabled={!!spinner}
            >
              {t('Send')}
            </SC.ControlButton>
          </Stack>
        </form>
      </Stack>
      <Box sx={{ height: '10vh' }}></Box>
    </SC.AuthFormContainer>
  );
};
