import React from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { CommonLayout } from '../components/CommonLayout';
import { PasswordCreatePage } from 'pages/PasswordCreatePage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <CommonLayout />,
    children: [
      {
        path: '/',
        element: <PasswordCreatePage />,
      },

      {
        path: '*',
        element: <Navigate to={'/'} />,
      },
    ],
  },
]);

export const CreatePassword = () => <RouterProvider router={router} />;
