import React from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { DashboardPage } from 'pages/DashboardPage';
import { MyAccountPage } from 'pages/MyAccountPage';
import { CommonLayout } from '../components/CommonLayout';
import { ErrorPage } from '../pages/ErrorPage';
import { DeviceDetails } from 'pages/DeviceDetails';
import { WebView } from 'pages/WebView';
import { HomecoreErrorPage } from 'pages/HomecoreErrorPage';
import { InvitePage } from 'pages/InvitePage';
import { WebViewWithoutSerialNumber } from 'pages/WebViewWithoutSerialNumber';
import { ReloadGetwayViewUrl } from 'components/ReloadGetwayViewUrl';
import { TermsPage } from 'pages/TermsPage';
import { PasswordChangePage } from 'pages/PasswordChangePage';
import { EmailChangePage } from 'pages/EmailChangePage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <CommonLayout />,
    children: [
      {
        path: '/',
        element: <DashboardPage />,
      },
      {
        path: '/my_profile',
        element: <MyAccountPage />,
      },
      {
        path: '/details/:serialNumber',
        element: <DeviceDetails />,
      },
      {
        path: '/register',
        element: <Navigate to={'/'} />,
      },
      {
        path: '/forgot',
        element: <Navigate to={'/'} />,
      },
      {
        path: '/login',
        element: <Navigate to={'/'} />,
      },
      {
        path: '/confirm_registration/:token',
        element: <Navigate to={'/'} />,
      },
      {
        path: '/device_is_not_available',
        element: <HomecoreErrorPage />,
      },
      {
        path: '/invite/:inviteToken',
        element: <InvitePage />,
      },
      {
        path: '/error',
        element: <ErrorPage />,
      },
      {
        path: '/app_view/:serialNumber',
        element: <WebView />,
      },
      {
        path: '/app_view',
        element: <WebViewWithoutSerialNumber />,
      },
      {
        path: '/view',
        element: <ReloadGetwayViewUrl />,
      },
      {
        path: '/terms',
        element: <TermsPage />,
      },
      {
        path: '/password_change/:token',
        element: <PasswordChangePage />,
      },
      {
        path: '/email_change/:token',
        element: <EmailChangePage />,
      },
      {
        path: '*',
        element: <Navigate to={'/'} />,
      },
    ],
  },
]);

export const App = () => <RouterProvider router={router} />;
