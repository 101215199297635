import React, { useState, useContext } from 'react';
import { GlobalContext } from 'store/globalContext';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorPage } from './ErrorPage';
import { Box, Stack } from '@mui/system';
import { Button, Grid, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { InviteUserDialog } from 'components/InviteUserDialog';
import { useAppSelector } from 'store/hooks';
import { useGateways } from 'hooks/useGateways';
import { removeAccessForUser } from 'api/assignmentApi';
import { useApiRequest } from 'api/useApiRequest';
import * as SC from './DeviceDetails.style';
import * as FP from 'utils/fp-js';

export const DeviceDetails = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const { callApiRequest } = useApiRequest();

  const { openConfirmationWithCallback } = useContext(GlobalContext);
  const currentUser = useAppSelector(state => state.user.email);
  const [isInviteDialogOpened, setIsInviteDialogOpen] = useState(false);
  const { serialNumber } = useParams();

  const { gatewayList, refreshGatewayList } = useGateways();
  const currentDevice = gatewayList?.find(
    gateway => gateway.serialNumber === serialNumber
  );

  if (gatewayList && !currentDevice) return <ErrorPage />;

  const { macAddress = '', assignments = [] } = currentDevice ?? {};

  const sortedAssignments = assignments
    .filter(({ userMail }) => userMail !== currentUser)
    .sort((a, b) => (a.userMail > b.userMail ? 1 : -1));

  const gatewayName = assignments.reduce(
    (acc, { gatewayName, userMail }) =>
      userMail === currentUser ? gatewayName : acc,
    ''
  );

  /** @type {import("types/commonTypes").GatewayUserRole} */
  const userRole = assignments.reduce(
    (acc, { deviceUserRole, userMail }) =>
      userMail === currentUser ? deviceUserRole : acc,
    'Guest'
  );

  const deviceOwner = assignments.reduce(
    (acc, { deviceUserRole, userMail }) =>
      deviceUserRole === 'Owner' ? userMail : acc,
    ''
  );

  const id = assignments.reduce(
    (acc, { id, userMail }) => (userMail === currentUser ? id : acc),
    ''
  );

  const deleteDevice = () => {
    FP.asyncEither({
      value: callApiRequest({
        apiRequest: removeAccessForUser,
        params: {
          id,
          gatewaySerialNumber: serialNumber,
          userMail: currentUser,
        },
      }),
      rightFn: () => navigate('/'),
    });
  };

  const deleteUserAccess = (email, id) => {
    FP.asyncEither({
      value: callApiRequest({
        apiRequest: removeAccessForUser,
        params: {
          id,
          gatewaySerialNumber: serialNumber,
          userMail: email,
        },
      }),
      rightFn: refreshGatewayList,
    });
  };

  const handleDeleteDevice = () =>
    openConfirmationWithCallback({
      title: 'Delete Device',
      message:
        'If you delete the device, it will be disconnected from your account and smartphone app. You can still access it on your home network.',
      // extraData: serialNumber,
      callbackFn: () => deleteDevice(),
      buttonLabels: [t('Cancel'), t('Delete Device')],
      buttonColors: ['primary', 'secondary'],
      closeIcon: false,
    });

  const handleDeleteUser = (email, id) =>
    openConfirmationWithCallback({
      title: 'Delete User',
      message: 'Do you really want to remove access for this user?',
      extraData: email,
      callbackFn: () => deleteUserAccess(email, id),
      buttonLabels: [t('Cancel'), t('Delete User')],
      buttonColors: ['primary', 'secondary'],
      closeIcon: false,
    });

  return (
    <>
      <Stack gap={3}>
        <SC.BackButton onClick={() => navigate('/')}>
          <SC.BackButtonContent sx={SC.BackButtonContentSx}>
            <ArrowBackIosNewIcon fontSize="large" />
            {t('Go back to the Home screen')}
          </SC.BackButtonContent>
        </SC.BackButton>
        {gatewayList && (
          <Stack direction={'row'} justifyContent={'space-between'} gap={6}>
            <SC.PropertiesHolder gap={3} flex={1}>
              <SC.Title sx={SC.TitleSx}>
                {t('General Device Information')}
              </SC.Title>
              {gatewayName && (
                <SC.Property>
                  <SC.Label>{t('Name')}</SC.Label>
                  <SC.Value>{gatewayName}</SC.Value>
                </SC.Property>
              )}
              <SC.Property>
                <SC.Label>{t('Serial Number')}</SC.Label>
                <SC.Value>{serialNumber}</SC.Value>
              </SC.Property>
              <SC.Property>
                <SC.Label>{t('MAC Address')}</SC.Label>
                <SC.Value>{macAddress}</SC.Value>
              </SC.Property>
              <SC.Property>
                <SC.Label>{t('Access')}</SC.Label>
                <SC.Value>{userRole}</SC.Value>
              </SC.Property>
              {userRole === 'Guest' && (
                <SC.Property>
                  <SC.Label>{t('Device owner')}</SC.Label>
                  <SC.Value>{deviceOwner}</SC.Value>
                </SC.Property>
              )}
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleDeleteDevice}
              >
                {t('Delete Device')}
              </Button>
            </SC.PropertiesHolder>
            {userRole === 'Owner' && (
              <Stack gap={2} flex={1} maxWidth={'47%'}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <SC.Title sx={SC.TitleSx}>{t('Shared with')}</SC.Title>
                  {userRole === 'Owner' && (
                    <Button
                      variant="outlined"
                      onClick={() => setIsInviteDialogOpen(true)}
                    >
                      {t('Invite User')}
                    </Button>
                  )}
                </Stack>
                {sortedAssignments?.map(({ id, userMail, inviteAccepted }) => (
                  <SC.GuestListItem key={`assignment-${id}`} container gap={1}>
                    {inviteAccepted ? (
                      <>
                        <Grid item xs={7} sx={{ wordWrap: 'break-word' }}>
                          <SC.Value>{userMail}</SC.Value>
                        </Grid>
                        <Grid item xs={4} textAlign={'center'}>
                          <Box sx={{ color: 'green' }}>{t('Accepted')}</Box>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={7} sx={{ wordWrap: 'break-word' }}>
                          <SC.Label>{userMail}</SC.Label>
                        </Grid>
                        <Grid item xs={4} textAlign={'center'}>
                          <Box sx={{ color: 'red' }}>{t('Not Accepted')}</Box>
                        </Grid>
                      </>
                    )}
                    <Grid item xs zeroMinWidth>
                      <IconButton
                        onClick={() => handleDeleteUser(userMail, id)}
                      >
                        <DeleteForeverIcon color="secondary" />
                      </IconButton>
                    </Grid>
                  </SC.GuestListItem>
                ))}
              </Stack>
            )}
          </Stack>
        )}
      </Stack>
      <InviteUserDialog
        open={isInviteDialogOpened}
        handleClose={() => setIsInviteDialogOpen(false)}
        gatewaySerialNumber={serialNumber}
        refreshGatewayList={refreshGatewayList}
      />
    </>
  );
};
