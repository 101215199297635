import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { useApiRequest } from 'api/useApiRequest';
import {
  changePasswordByTokenAndLogin,
  reissuePasswordChangeToken,
} from 'api/authApi';
import { useUnrestrictedEffect } from 'hooks/useUnrestrictedEffect';
import { restoreAuth } from 'store/reducers/userReducer';
import * as FP from 'utils/fp-js';

const defaultValues = {
  password: '',
  password_repeat: '',
};

export const usePasswordChangePage = () => {
  const { callApiRequest } = useApiRequest();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const [newToken, setNewToken] = useState('');

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const {
    register,
    control,
    reset,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty },
    trigger,
    watch,
  } = useForm({
    defaultValues,
  });

  const onSubmit = handleSubmit(async ({ password }) => {
    FP.asyncEither({
      value: callApiRequest({
        apiRequest: changePasswordByTokenAndLogin,
        params: {
          token: newToken,
          newPassword: password,
        },
      }),
      rightFn: value => dispatch(restoreAuth(value?.refresh?.token)),
    });
  });

  useUnrestrictedEffect({
    loadFn: () => {
      FP.asyncEither({
        value: callApiRequest({
          apiRequest: reissuePasswordChangeToken,
          params: {
            token,
          },
          skipErrorHandling: true,
        }),
        leftFn: () => navigate('/'),
        rightFn: value => setNewToken(value?.token),
      });
    },
  });

  return {
    onSubmit,
    isPasswordVisible,
    setIsPasswordVisible,
    errors,
    isDirty,
    trigger,
    getValues,
    setValue,
    register,
    reset,
    control,
    watch,
    newToken,
    setNewToken,
  };
};
