import { Box, styled } from '@mui/material';

export const NumeratedList = styled('ol')`
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const Heading = styled(Box)`
  margin: 0;
  color: ${({ theme }) => theme.palette.primary.dark};
`;

export const Content = styled(Box)`
  margin: 0 0 1em 0;
  font-size: 0.8em;
  text-align: justify;
  color: ${({ theme }) => theme.palette.text.primary};
`;
