import React from 'react';
import { useAppSelector } from 'store/hooks';
import { useAppTranslation } from 'i18n/useAppTranslation';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { emailValidation } from 'models/validations';
import {
  Button,
  Stack,
  Divider,
  InputLabel,
  InputAdornment,
  Box,
  useTheme,
} from '@mui/material';
import { useEmailChangePage } from './EmailChangePage.hook';
import * as UI from 'UI';
import * as SC from 'SC';

export const EmailChangePage = () => {
  const { t } = useAppTranslation();
  const theme = useTheme();
  const spinner = useAppSelector(state => state.spinner);
  const { control, onSubmit, errors, newToken } = useEmailChangePage();

  if (!newToken) return null;

  return (
    <Stack justifyContent={'center'} alignItems={'center'} height={'100%'}>
      <Stack gap={2}>
        <SC.AuthTitle sx={SC.AuthTitleSx}>{t('Set E-mail')}</SC.AuthTitle>
        <Stack direction={'row'} justifyContent={'center'} gap={1}>
          <SC.Text>
            {t('Set the new email that will be used for login')}
          </SC.Text>
        </Stack>
        <Divider />
        <form onSubmit={onSubmit} className="mui-form">
          <Stack gap={2}>
            <Stack alignItems={'flex-start'}>
              <InputLabel htmlFor="email">{t('Enter E-mail')}</InputLabel>
              <UI.FormTextField
                id="email"
                name="email"
                control={control}
                rules={emailValidation}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlinedIcon
                        sx={{
                          color: errors?.email
                            ? theme.palette.error.main
                            : theme.palette.text.primary,
                        }}
                      />
                    </InputAdornment>
                  ),
                  autoComplete: 'off',
                }}
              />
            </Stack>
            <Button type="submit" variant="contained" disabled={!!spinner}>
              {t('Set E-mail')}
            </Button>
          </Stack>
        </form>
      </Stack>
      <Box sx={{ height: '25vh' }}></Box>
    </Stack>
  );
};
