import { Stack, styled, Button, Grid } from '@mui/material';
import { Text } from 'SC';

export { Title, TitleSx, BackButtonContent, BackButtonContentSx } from 'SC';

export const BackButton = styled(Button)`
  align-self: flex-start;
`;

export const PropertiesHolder = styled(Stack)`
  max-width: 45%;
`;

export const Property = styled(Stack)`
  flex-wrap: wrap;
  gap: 5px;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
`;

export const Value = styled(Text)`
  color: #284a70;
  opacity: 1;
`;

export const Label = styled(Value)`
  opacity: 0.5;
`;

export const GuestListItem = styled(Grid)`
  align-items: center;
  flex-wrap: wrap;
`;
