import {
  createAction,
  createAsyncThunk,
  createReducer,
} from '@reduxjs/toolkit';
import { hideError, showError } from './errorReducer';
import { hideSpinner, showSpinner } from './spinnerReducer';

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @type {import('types/commonTypes').AssignmentWithGateway[]} */
const initialState = [];

export const resetAssignments = createAction('assignments/reset');

export const getAssignments = createAsyncThunk(
  'assignments/get',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(showSpinner());
    thunkAPI.dispatch(hideError());

    try {
      // @ts-ignore
      const result = await thunkAPI.extra.assignmentApi.getAssignmentList();

      if (result?.error) {
        thunkAPI.dispatch(showError(result.error));

        return Promise.reject();
      }

      return result;
    } catch (e) {
      thunkAPI.dispatch(showError(e.error));

      return Promise.reject();
    } finally {
      thunkAPI.dispatch(hideSpinner());
    }
  }
);

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(resetAssignments, () => initialState)

    .addCase(getAssignments.fulfilled, (state, { payload }) => {
      return payload.value;
    })

    .addCase(getAssignments.rejected, (state, { payload }) => {
      return [];
    });
});

export default reducer;
