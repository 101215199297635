import React, { useContext } from 'react';
import { GlobalContext } from 'store/globalContext';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { confirmationConfig } from 'models/confirmationConfig';
import { closeConfirmation } from 'store/reducers/confirmationReducer';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Stack,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as CSC from 'SC';

export const Confirmation = () => {
  const dispatch = useAppDispatch();
  const { modalConfirmationType } = useAppSelector(state => state.confirmation);
  const isGlobalStateBasedPresent = modalConfirmationType !== 'NONE';

  const { confirmationWithCallback, closeConfirmationWithCallback } =
    useContext(GlobalContext);
  const isGlobalContextBasedPresent = !!confirmationWithCallback?.title;
  const isConditionalCallbackPresent =
    !!confirmationWithCallback?.conditionalCallbackFn;

  const { t } = useAppTranslation();

  const contextBasedData = isGlobalContextBasedPresent
    ? {
        title: confirmationWithCallback?.title,
        message: confirmationWithCallback?.message,
        abort: () => closeConfirmationWithCallback(),
        proceed: () => {
          confirmationWithCallback?.conditionalCallbackFn &&
            confirmationWithCallback.conditionalCallbackFn();
          setTimeout(() => confirmationWithCallback?.callbackFn());
          closeConfirmationWithCallback();
        },
        proceedWithoutConditionalCallback: () => {
          setTimeout(() => confirmationWithCallback?.callbackFn());
          closeConfirmationWithCallback();
        },
        extraData: confirmationWithCallback?.extraData,
        buttonLabels: confirmationWithCallback?.buttonLabels,
        buttonColors: confirmationWithCallback?.buttonColors,
        closeIcon: confirmationWithCallback?.closeIcon ?? true,
      }
    : {};

  const {
    title = undefined,
    message = undefined,
    proceed = () => null,
    proceedWithoutConditionalCallback = () => null,
    abort = () => null,
    extraData = undefined,
    buttonLabels = [],
    buttonColors = [],
    closeIcon = true,
  } = isGlobalStateBasedPresent
    ? {
        title: confirmationConfig[modalConfirmationType]?.title,
        message: confirmationConfig[modalConfirmationType]?.message,
        abort: () => dispatch(closeConfirmation()),
        proceed: () => {
          confirmationConfig[modalConfirmationType]?.dispatchAction &&
            dispatch(
              confirmationConfig[modalConfirmationType].dispatchAction()
            );
          dispatch(closeConfirmation());
        },
      }
    : contextBasedData;

  return (
    (isGlobalStateBasedPresent || isGlobalContextBasedPresent) && (
      <Dialog
        open
        aria-labelledby="confirmation-title"
        aria-describedby="confirmation-description"
      >
        {closeIcon && (
          <Stack
            alignItems={'flex-end'}
            sx={{ margin: 0, position: 'absolute', top: 0, right: 0 }}
          >
            <IconButton aria-label="close" onClick={abort}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Stack>
        )}
        {title && <DialogTitle id="confirmation-title">{t(title)}</DialogTitle>}
        {message && (
          <DialogContent id="confirmation-description">
            {t(message)}
            {extraData && (
              <Box>
                {Array.isArray(extraData) ? extraData.join(', ') : extraData}
              </Box>
            )}
            <CSC.EmptySpace />
          </DialogContent>
        )}
        <DialogActions>
          <CSC.DialogButton
            variant="outlined"
            color={buttonColors[0]}
            onClick={abort}
          >
            {buttonLabels[0] ?? t('Abort')}
          </CSC.DialogButton>
          &nbsp;
          {isConditionalCallbackPresent ? (
            <>
              <CSC.DialogButton
                variant="contained"
                color={buttonColors[1] ?? 'warning'}
                onClick={proceedWithoutConditionalCallback}
              >
                {buttonLabels[1] ?? t('Discard & Proceed')}
              </CSC.DialogButton>
              &nbsp;
              <CSC.DialogButton
                variant="contained"
                color={buttonColors[2] ?? 'success'}
                onClick={proceed}
              >
                {buttonLabels[2] ?? t('Save & Proceed')}
              </CSC.DialogButton>
            </>
          ) : (
            <>
              <CSC.DialogButton
                variant="contained"
                color={buttonColors[1]}
                onClick={proceed}
              >
                {buttonLabels[1] ?? t('Proceed')}
              </CSC.DialogButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    )
  );
};
