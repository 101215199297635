import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { Box, Grid, Stack, styled } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { LoginPage } from 'pages/LoginPage';
import { RegistrationPage } from 'pages/RegistartionPage';
import { ErrorDialog } from 'components/ErrorDialog';
import { LanguageSelector } from 'components/LanguageSelector';
import { useAppSelector } from 'store/hooks';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { ModalSpinner } from 'components/ModalSpinner';
import { TermsPage } from 'pages/TermsPage';
import { CookieConfirmation } from 'components/CookieConfirmation';
import { PasswordRecoveryPage } from 'pages/PasswordRecoveryPage';
import { PasswordChangePage } from 'pages/PasswordChangePage';
import BackgroundPic from 'assets/img/background_pic.png';
import * as SC from 'pages/DeviceDetails.style';
import { ConfirmRegistrationPage } from 'pages/ConfirmRegistartionPage';
import { EmptySpace } from 'SC';
import { NewUserRegistrationPage } from 'pages/NewUserRegistrationPage';

const OutletContainer = styled(Stack)`
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow-y: auto;
`;

const CommonLayout = () => {
  const { t } = useAppTranslation();
  const navigate = useNavigate();
  const spinner = useAppSelector(state => state.spinner);
  const { pathname } = useLocation();
  const isTermsPage = pathname.startsWith('/terms');
  const isForgotPasswordPage = pathname.startsWith('/forgot');

  const PictureHolder = styled(Box)`
    flex: 1 1 auto;
    padding: 3rem 2rem;
    max-width: 45%;
    color: white;
    background-color: #081315;
    background-image: url(${BackgroundPic});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    user-select: none;
  `;

  const TitleSx = {
    fontWeight: 'bold',
    fontSize: {
      lg: 30,
      md: 25,
      sm: 24,
      xs: 24,
    },
  };

  const DescriptionSx = {
    fontWeight: 'normal',
    fontSize: {
      lg: 20,
      md: 16,
      sm: 18,
      xs: 18,
    },
  };

  if (isTermsPage) {
    return (
      <Stack justifyContent={'center'} alignContent={'center'} height={'100%'}>
        <Box padding={'16px 16px'}>
          <Grid container>
            <Grid item xs={4}>
              <SC.BackButton onClick={() => navigate(-1)}>
                <SC.BackButtonContent sx={SC.BackButtonContentSx}>
                  <ArrowBackIosNewIcon fontSize="large" />
                  {t('Back to the previous page')}
                </SC.BackButtonContent>
              </SC.BackButton>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'center' }}>
              <LanguageSelector />
            </Grid>
            <Grid item xs={4}>
              <EmptySpace />
            </Grid>
          </Grid>
        </Box>
        <Outlet />
        <ModalSpinner open={spinner} />
        <CookieConfirmation />
      </Stack>
    );
  }

  return (
    <Stack justifyContent={'center'} height={'100%'} minHeight={'720px'}>
      <Stack direction={'row'} justifyContent={'center'} flex={1}>
        <Stack flex={1} sx={{ padding: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              {isForgotPasswordPage && (
                <SC.BackButton onClick={() => navigate('/')}>
                  <SC.BackButtonContent sx={SC.BackButtonContentSx}>
                    <ArrowBackIosNewIcon fontSize="large" />
                    {t('Log in')}
                  </SC.BackButtonContent>
                </SC.BackButton>
              )}
              {!isForgotPasswordPage && (
                <SC.BackButton
                  onClick={() => navigate('//https://pytronic.com')}
                >
                  <SC.BackButtonContent sx={SC.BackButtonContentSx}>
                    <ArrowBackIosNewIcon fontSize="large" />
                    {t('Website')}
                  </SC.BackButtonContent>
                </SC.BackButton>
              )}
            </Grid>
            <Grid item xs={4}>
              <Stack alignItems={'center'}>
                <LanguageSelector />
              </Stack>
            </Grid>
          </Grid>
          <ErrorDialog />
          <OutletContainer>
            <Outlet />
          </OutletContainer>
        </Stack>
        <PictureHolder>
          <Stack maxWidth={'450px'}>
            <Box sx={TitleSx}>{t('A home tailored to you')}</Box>
            <Box sx={DescriptionSx}>
              {t(
                'Manage the purchased core boxes through the admin application'
              )}
            </Box>
          </Stack>
        </PictureHolder>
      </Stack>
      <ModalSpinner open={spinner} />
      <CookieConfirmation />
    </Stack>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <CommonLayout />,
    children: [
      {
        path: '/',
        element: <LoginPage />,
      },
      {
        path: '/register',
        element: <RegistrationPage />,
      },
      {
        path: '/invite_new_user/:token',
        element: <NewUserRegistrationPage />,
      },
      {
        path: '/confirm_registration/:token',
        element: <ConfirmRegistrationPage />,
      },
      {
        path: '/forgot',
        element: <PasswordRecoveryPage />,
      },
      {
        path: '/password_change/:token',
        element: <PasswordChangePage />,
      },
      {
        path: '/terms',
        element: <TermsPage />,
      },
      {
        path: '/login',
        element: <Navigate to={'/'} />,
      },
      {
        path: '*',
        element: <LoginPage />,
      },
    ],
  },
]);

export const Auth = () => <RouterProvider router={router} />;
