import React, { useEffect, useState, useContext } from 'react';
import { GlobalContext } from 'store/globalContext';
import {
  Button,
  Stack,
  Grid,
  InputAdornment,
  useTheme,
  InputLabel,
  Box,
} from '@mui/material';
import * as UI from 'UI';
import CheckEmailImage from 'assets/img/check_email.svg';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { useForm } from 'react-hook-form';
import { fullNameValidation, emailValidation } from 'models/validations';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ChangePasswordDialog } from 'components/ChangePasswordDialog';
import { DeleteAccountDialog } from 'components/DeleteAccountDialog';
import * as CSC from 'SC';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { sendChangeEmailLink, updateAccount } from 'api/authApi';
import { useApiRequest } from 'api/useApiRequest';
import { localStorageNameEnum } from 'models/constants';
import { restoreAuth } from 'store/reducers/userReducer';
import * as FP from 'utils/fp-js';

export const MyAccountPage = () => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const { callApiRequest } = useApiRequest();

  const theme = useTheme();
  const { showWarning } = useContext(GlobalContext);
  const user = useAppSelector(state => state.user);

  const [isPasswordChangeDialogOpen, setIsPasswordChangeDialogOpen] =
    useState(false);
  const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] =
    useState(false);

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: user,
  });

  const onSubmit = () => {
    const newUser = getValues();
    FP.asyncEither({
      value: callApiRequest({
        apiRequest: updateAccount,
        params: newUser,
      }),
      rightFn: value => {
        dispatch(restoreAuth(value?.refresh?.token));
        reset(newUser);
      },
    });
  };

  const handleChangeEmail = () => {
    const lang = localStorage.getItem(localStorageNameEnum.LANG) ?? 'en';
    FP.asyncEither({
      value: callApiRequest({
        apiRequest: sendChangeEmailLink,
        params: {
          lang,
        },
      }),
      rightFn: () =>
        showWarning({
          title: (
            <img src={CheckEmailImage} width="80px" alt="check email icon" />
          ),
          content: (
            <Stack>
              <h2>{t('Instructions sent')}</h2>
              <Box sx={{ maxWidth: '400px' }}>
                {t(
                  'Check your e-mail for instructions on how to change your Pytronic ID'
                )}
              </Box>
            </Stack>
          ),
        }),
    });
  };

  useEffect(() => {
    reset(user);
  }, [reset, user]);

  return (
    <Box sx={{ padding: '1rem' }}>
      <Grid container spacing={10}>
        <Grid item xs={6}>
          <form>
            <Stack gap={3}>
              <Stack gap={1}>
                <CSC.Title sx={CSC.TitleSx}>
                  {t('Personal Information')}
                </CSC.Title>
                <Stack alignItems={'flex-start'}>
                  <InputLabel htmlFor="fullname">{t('Full Name')}</InputLabel>
                  <UI.FormTextField
                    id="fullname"
                    name="fullName"
                    control={control}
                    rules={fullNameValidation}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ManageAccountsOutlinedIcon
                            sx={{
                              color: theme.palette.text.primary,
                            }}
                          />
                        </InputAdornment>
                      ),
                      autoComplete: 'off',
                    }}
                  />
                </Stack>
              </Stack>
              {isDirty && (
                <Stack direction={'row'} gap={1}>
                  <Button variant="outlined" onClick={() => reset(user)}>
                    {t('Discard Changes')}
                  </Button>
                  <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                    {t('Save Changes')}
                  </Button>
                </Stack>
              )}
            </Stack>
          </form>
        </Grid>
        <Grid item xs={6}>
          <form>
            <Stack gap={3}>
              <Stack gap={1}>
                <CSC.Title sx={CSC.TitleSx}>{t('Change E-mail')}</CSC.Title>
                <Stack alignItems={'flex-start'}>
                  <InputLabel htmlFor="email">E-mail</InputLabel>
                  <UI.FormTextField
                    id="email"
                    name="email"
                    control={control}
                    rules={emailValidation}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ManageAccountsOutlinedIcon
                            sx={{
                              color: theme.palette.text.primary,
                            }}
                          />
                        </InputAdornment>
                      ),
                      autoComplete: 'off',
                      readOnly: true,
                    }}
                    sx={{
                      '& .MuiInputBase-input': {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      },
                    }}
                  />
                </Stack>
              </Stack>
              <CSC.ControlButton
                variant="contained"
                sx={{ alignSelf: 'flex-start' }}
                onClick={handleChangeEmail}
              >
                {t('Send a link for email change')}
              </CSC.ControlButton>
            </Stack>
          </form>
        </Grid>
        <Grid item xs={6}>
          <CSC.Title sx={CSC.TitleSx}>{t('Change Password')}</CSC.Title>
          <Stack gap={2}>
            <CSC.Text sx={CSC.TextSx}>
              {t('You need the old password to set a new one')}
            </CSC.Text>
            <Button
              variant="outlined"
              onClick={() => setIsPasswordChangeDialogOpen(true)}
              sx={{ alignSelf: 'flex-start' }}
            >
              {t('Set New Password')}
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <CSC.Title sx={CSC.TitleSx}>{t('Delete Account')}</CSC.Title>
          <Stack gap={2}>
            <CSC.Text sx={CSC.TextSx}>
              {t('You need a password to delete your account')}
            </CSC.Text>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ alignSelf: 'flex-start' }}
              onClick={() => setIsDeleteAccountDialogOpen(true)}
            >
              {t('Delete Account')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <ChangePasswordDialog
        open={isPasswordChangeDialogOpen}
        handleClose={() => setIsPasswordChangeDialogOpen(false)}
      />
      <DeleteAccountDialog
        open={isDeleteAccountDialogOpen}
        handleClose={() => setIsDeleteAccountDialogOpen(false)}
      />
    </Box>
  );
};
