import React, { useContext } from 'react';
import { GlobalContext } from 'store/globalContext';
import * as UI from 'UI';
import * as CSC from 'SC';
import { InputAdornment, Stack, InputLabel } from '@mui/material';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { useForm } from 'react-hook-form';
import { emailValidation } from 'models/validations';
import { inviteGuest } from 'api/assignmentApi';
import { useApiRequest } from 'api/useApiRequest';
import { localStorageNameEnum } from 'models/constants';
import * as FP from 'utils/fp-js';

const defaultFormData = {
  email: '',
};

export const InviteUserDialog = ({
  open,
  handleClose,
  gatewaySerialNumber,
  refreshGatewayList,
}) => {
  const { t } = useAppTranslation();
  const { showWarning } = useContext(GlobalContext);
  const { callApiRequest } = useApiRequest();

  const { control, handleSubmit, getValues, reset } = useForm({
    defaultValues: defaultFormData,
  });

  const onSubmit = () => {
    const newUser = getValues()?.email?.trim() ?? '';
    const lang = window.localStorage.getItem(localStorageNameEnum.LANG) ?? 'en';

    closeAndReset();

    FP.asyncEither({
      value: callApiRequest({
        apiRequest: inviteGuest,
        params: { gatewaySerialNumber, userMail: newUser, lang },
      }),
      rightFn: () => {
        showWarning({
          title: t('Invite sent successfully'),
          content: <CSC.EmptySpace />,
        });
        setTimeout(() => refreshGatewayList());
      },
    });
  };

  const closeAndReset = () => {
    reset(defaultFormData);
    handleClose();
  };

  return (
    <UI.DialogWindow
      open={open}
      withCloseButton
      handleClose={closeAndReset}
      title={t('Invite User')}
      content={
        <>
          <form>
            <Stack gap={2}>
              {t(
                'Enter E-mail of the user you want to give access to your Homecore'
              )}
              <Stack alignItems={'flex-start'}>
                <InputLabel htmlFor="email">{t('Enter E-mail')}</InputLabel>
                <UI.FormTextField
                  id="email"
                  name="email"
                  control={control}
                  rules={emailValidation}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <UI.AccountIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>
          </form>
          <CSC.EmptySpace />
        </>
      }
      actions={
        <CSC.DialogButton variant="contained" onClick={handleSubmit(onSubmit)}>
          {'Send Invitation'}
        </CSC.DialogButton>
      }
    />
  );
};
