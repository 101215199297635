import React from 'react';
import { Link } from 'react-router-dom';
import { Content, Heading, NumeratedList } from './Terms.style';

export const TermsSv = () => (
  <NumeratedList>
    <li>
      <Heading>Behandling av personuppgifter</Heading>
      <Content>
        Vi på Pytronic värnar om den personliga integriteten. Vi behandlar
        alltid personuppgifter på ett ansvarsfullt sätt i enlighet med gällande
        lagstiftning. Med ”behandling” avses i princip all form av hantering av
        en personuppgift och kan exempelvis innefatta insamling, lagring,
        ändring, användning eller radering av sådan uppgift. I denna policy, som
        gäller för alla som på något sätt nyttjar Pytronics tjänster eller
        produkter eller på annat sätt kommer i kontakt med oss, redogör vi bland
        annat för hur och varför vi behandlar dina personuppgifter och vilka
        rättigheter du har enligt gällande personuppgiftslagstiftning.
      </Content>
    </li>
    <li>
      <Heading>Personuppgiftsansvarig</Heading>
      <Content>
        Pytronic AB, organisationsnummer 559104-1107, Speditionsvägen 36, 14250
        Skogås, är personuppgiftsansvarig för företagets behandling av
        personuppgifter.
      </Content>
    </li>
    <li>
      <Heading>Vilka personuppgifter behandlas?</Heading>
      <Content>
        Uppgifter som du själv lämnar till oss. Vi samlar in och behandlar
        personuppgifter som du lämnar till oss, t.ex. i samband med att du
        besöker vår hemsida, ingår avtal med oss avseende tjänster eller varor,
        anmäler dig till vårt nyhetsbrev, kontaktar oss eller använder våra
        digitala kanaler. Uppgifter för att fullgöra våra avtalsförpliktelser Vi
        samlar in och behandlar personuppgifter som är nödvändiga för att vi ska
        kunna fullgöra våra rättigheter och skyldigheter enligt avtal. Om
        nödvändiga personuppgifter inte tillhandahålls kan det medföra att vi
        inte kan uppfylla våra rättigheter och skyldigheter enligt avtal.
      </Content>
    </li>
    <li>
      <Heading>I vilket syfte behandlar vi personuppgifter?</Heading>
      <Content>
        Vi behandlar personuppgifter främst för att kunna fullgöra våra
        förpliktelser och för att kunna tillhandahålla tjänster och varor till
        dig. Vi behandlar dina uppgifter för att:
        <ul>
          <li>Fullgöra våra rättigheter och skyldigheter enligt avtal</li>
          <li>Administrera och tillhandahålla våra tjänster och varor</li>
          <li>Hantera kundtjänstärenden och övriga förfrågningar</li>
          <li>
            Lämna information och marknadsföra varor och tjänster som du kan
            vara intresserad av
          </li>
          <li>Fullgörande av våra rättsliga skyldigheter</li>
          <li>
            Förbättra våra tjänster och varor, exempelvis genom att undersöka
            och utvärdera kundnöjdheten eller marknaden
          </li>
        </ul>
      </Content>
    </li>
    <li>
      <Heading>Hur länge sparas personuppgifterna?</Heading>
      <Content>
        Dina personuppgifter behandlas endast så länge som det är nödvändigt för
        de ändamål de samlats in för – exempelvis så länge det krävs för att vi
        ska kunna fullgöra de rättigheter och skyldigheter som vi har med
        anledning av ett avtal. Vi kan komma att spara uppgifterna längre om det
        krävs enligt lag eller för att bevaka våra rättsliga intressen, t.ex. om
        det pågår en juridisk process. Dina personuppgifter gallras när de inte
        längre är relevanta för de ändamål som de har samlats in för.
      </Content>
    </li>
    <li>
      <Heading>Laglig grund för behandling av personuppgifterna</Heading>
      <Content>
        Vår behandling av personuppgifter sker i huvudsak för att fullgöra och
        administrera våra rättigheter och skyldigheter enligt avtal. Detta sker
        med stöd av den rättsliga grunden samtycke, fullgörande av avtal eller
        med stöd av ett berättigat intresse. För annan behandling som utförs har
        vi ett berättigat intresse att behandla personuppgifterna, t.ex. för att
        skicka information, göra kundundersökningar och marknadsföring samt
        tillhandahålla tjänster som är relaterade till vårt avtalsförhållande.
      </Content>
    </li>
    <li>
      <Heading>Vilka kan vi komma att dela dina personuppgifter med?</Heading>
      <Content>
        I den utsträckning det är nödvändigt delar vi dina personuppgifter med
        de leverantörer och samarbetspartners som vi samarbetar med och som
        utför tjänster för vår räkning. Dina personuppgifter kan främst komma
        att delas med:
        <ul>
          <li>
            Företag som hjälper oss med vår betalningshantering, till exempel
            banker
          </li>
          <li>
            Företag som vi inhandlar varor och tjänster ifrån för att vi ska
            kunna fullgöra våra avtalsförpliktelser (exempelvis leverantör av
            möbler och inventarier)
          </li>
          <li>Företag som hjälper oss med arkivering</li>
          <li>
            Företag som utför kundundersökningar åt oss, till exempel
            nöjdhetsundersökningar
          </li>
          <li>
            IT-tjänster (nödvändig drift, teknisk support och underhåll av våra
            IT-lösningar)
          </li>
          <li>
            Myndigheter – Ibland kan vi på grund av lag eller myndighetsbeslut
            vara skyldiga att lämna ut personuppgifter
          </li>
        </ul>
      </Content>
    </li>
    <li>
      <Heading>Hur skyddas personuppgifterna?</Heading>
      <Content>
        Vi behandlar enbart personuppgifter som vi bedömer är nödvändiga för att
        bedriva vår verksamhet och endast så länge som det behövs för avsett
        syfte. I den utsträckning det är möjligt begränsar vi vilka anställda
        som får ta del av olika typer av personuppgifter.
      </Content>
    </li>
    <li>
      <Heading>Rättigheter enligt dataskyddslagstiftningen</Heading>
      <Content>
        Enligt dataskyddslagstiftningen har du som individ vissa rättigheter vid
        behandling av dina personuppgifter.
        <br />
        <br />
        Rättelse och tillgång till dina personuppgifter
        <br />
        Som registrerad har du rätt att när som helst begära rättelse av
        felaktiga uppgifter eller ändra de personuppgifter som du lämnat. Du har
        också rätt att ta del av vilka personuppgifter vi har registrerade om
        dig. Detta gör du genom att begära ett så kallat registerutdrag.
        <br />
        <br />
        Återkalla samtycke med framtida verkan
        <br />
        Behandlar vi dina personuppgifter på den rättsliga grunden samtycke har
        du rätt att när som helst återkalla ditt fortsatta samtycke avseende
        framtida behandling utan att det påverkar lagligheten av den behandling
        som skett för tiden innan återkallandet.
        <br />
        <br />
        Invända mot behandling
        <br />
        Du har rätt att invända mot behandlingar som vi utför med stöd av en
        intresseavvägning. <br />
        Du kan när som helst avsäga dig kommunikationsutskick. Du kan
        avregistrera dig genom att klicka på en länk i det aktuella utskicket.
        <br />
        <br />
        Radering
        <br />
        Under vissa omständigheter har du rätt att få dina personuppgifter
        raderade. Detta gäller dock inte om vi exempelvis är skyldiga enligt lag
        att bevara uppgifterna.
        <br />
        <br />
        Begränsning av behandling
        <br />
        Du kan även ha rätt att begära att behandlingen av dina personuppgifter
        begränsas. Om du begär att behandlingen av dina uppgifter ska begränsas
        kan det dock medföra att vi inte kan uppfylla våra eventuella
        skyldigheter gentemot dig under den tid behandlingen begränsats.
        <br />
        <br />
        Dataportabilitet
        <br /> För det fall att du vill flytta dina uppgifter från oss har du
        även rätt att få en kopia på de personuppgifter som rör dig samlade i
        ett strukturerat, maskinläsbart format. Denna rätt omfattar endast
        uppgifter som du själv har delat med oss.
      </Content>
    </li>
    <li>
      <Heading>Cookies</Heading>
      <Content>När du besöker vår hemsida använder vi cookies.</Content>
    </li>
    <li>
      <Heading>Klagomål avseende vår behandling av personuppgifter</Heading>
      <Content>
        Skulle du inte vara nöjd med vårt sätt att behandla dina personuppgifter
        ber vi dig att kontakta oss och framföra dina synpunkter till{' '}
        <Link to="mailto:info@pytronic.com">info@pytronic.com</Link>. Om du inte
        är nöjd med vårt sätt att hantera ditt klagomål kan du framföra ditt
        klagomål till behörig tillsynsmyndighet, som för närvarande är
        Datainspektionen.
      </Content>
    </li>
    <li>
      <Heading>Ändringar i policyn</Heading>
      <Content>
        Vi förbehåller oss rätten att göra ändringar i vår privacy policy. Du
        hittar alltid den senaste versionen på vår hemsida. Vid uppdateringar
        som är av avgörande betydelse för behandling av personuppgifter kommer
        detta att kommuniceras.
        <br />
        <br />
        Tack för ditt förtroende!
      </Content>
    </li>
  </NumeratedList>
);
