import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { localStorageNameEnum } from 'models/constants';
import resources from './resources';

export const languageList = Object.keys(resources);

const storedLanguage = localStorage.getItem(localStorageNameEnum.LANG);
const defaultLanguage = languageList.includes(storedLanguage)
  ? storedLanguage
  : 'en';

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
});

const i18nTyped = {
  ...i18n,
  /** @type {(key: import("types/i18n.types").TranslationKeys) => string} */
  t: key => i18n.t(key),
};

export default i18nTyped;
