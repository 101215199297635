const matched = x => ({
  on: () => matched(x),
  otherwise: () => x,
});

/**
 * @template T
 * @param {T} x
 */
export const match = x => ({
  /**
   * @param {T|T[]} pred
   * @param {any} fn
   */
  on: (pred, fn) => {
    const getResult = () => (typeof fn === 'function' ? fn(x) : fn);

    if (typeof pred === 'function') {
      return pred(x) ? matched(getResult()) : match(x);
    }

    if (Array.isArray(pred)) {
      return pred.includes(x) ? matched(getResult()) : match(x);
    }

    return x === pred ? matched(getResult()) : match(x);
  },
  /**
   * @param {any} fn
   */
  otherwise: fn => {
    return typeof fn === 'function' ? fn(x) : fn;
  },
});

/**
 * @template T
 * @typedef {import("types/commonTypes").HttpResult<T>} HttpResult
 */

/**
 * @template T
 * @param {{
 *  value: HttpResult<T>;
 *  leftFn?: ((error: string) => void) | (() => void);
 *  rightFn?: ((value: T) => void) | (() => void);
 * }} props
 */
export const asyncEither = async ({
  value,
  leftFn = () => null,
  rightFn = () => null,
}) => {
  const result = await value;
  if (result?.error) {
    return leftFn(result.error);
  }
  return rightFn(result.value);
};
