import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useApiRequest } from 'api/useApiRequest';
import { changePassword } from 'api/authApi';
import {
  Stack,
  IconButton,
  InputLabel,
  InputAdornment,
  Alert,
  useTheme,
} from '@mui/material';
import LockIcon from '@mui/icons-material/LockOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAppTranslation } from 'i18n/useAppTranslation';
import {
  getPasswordConfirmValidation,
  passwordValidation,
} from 'models/validations';
import * as UI from 'UI';
import * as SC from 'SC';
import * as FP from 'utils/fp-js';

const defaultFormData = {
  oldPassword: '',
  newPassword: '',
  newPasswordRepeat: '',
};

export const ChangePasswordDialog = ({ open, handleClose }) => {
  const { t } = useAppTranslation();
  const theme = useTheme();
  const { callApiRequest } = useApiRequest();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [error, setError] = useState('');
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: defaultFormData,
  });

  const password = useRef('');
  password.current = watch('newPassword', '');

  const onSubmit = () => {
    const { oldPassword, newPassword } = getValues();
    setError('');

    FP.asyncEither({
      value: callApiRequest({
        apiRequest: changePassword,
        params: {
          oldPassword,
          newPassword,
        },
        skipErrorHandling: true,
      }),
      leftFn: setError,
      rightFn: () => {
        reset(defaultFormData);
        handleClose();
      },
    });
  };

  const closeAndReset = () => {
    setError('');
    reset(defaultFormData);
    handleClose();
  };

  return (
    <UI.DialogWindow
      open={open}
      withCloseButton
      handleClose={closeAndReset}
      title={t('Password change')}
      content={
        <>
          <Stack gap={4}>
            <Stack alignItems={'flex-start'}>
              <InputLabel htmlFor="oldPassword">
                {t('Enter current password')}
              </InputLabel>
              <UI.FormTextField
                id="oldPassword"
                name="oldPassword"
                control={control}
                rules={passwordValidation}
                type={isPasswordVisible ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon
                        sx={{
                          color: errors?.oldPassword
                            ? theme.palette.error.main
                            : theme.palette.text.primary,
                        }}
                      />
                    </InputAdornment>
                  ),
                  autoComplete: 'off',
                }}
              />
            </Stack>
            <Stack gap={2}>
              <Stack alignItems={'flex-start'}>
                <InputLabel htmlFor="newPassword">
                  {t('Create new password')}
                </InputLabel>
                <UI.FormTextField
                  id="newPassword"
                  name="newPassword"
                  control={control}
                  rules={passwordValidation}
                  type={isPasswordVisible ? 'text' : 'password'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon
                          sx={{
                            color: errors?.newPassword
                              ? theme.palette.error.main
                              : theme.palette.text.primary,
                          }}
                        />
                      </InputAdornment>
                    ),
                    autoComplete: 'off',
                  }}
                />
              </Stack>
              <Stack alignItems={'flex-start'}>
                <InputLabel htmlFor="newPasswordRepeat">
                  {t('Repeat new password')}
                </InputLabel>
                <UI.FormTextField
                  id="newPasswordRepeat"
                  name="newPasswordRepeat"
                  control={control}
                  rules={getPasswordConfirmValidation(password.current)}
                  type={isPasswordVisible ? 'text' : 'password'}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon
                          sx={{
                            color: errors?.newPasswordRepeat
                              ? theme.palette.error.main
                              : theme.palette.text.primary,
                          }}
                        />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        color="info"
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      >
                        {isPasswordVisible ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    ),
                    autoComplete: 'off',
                  }}
                />
              </Stack>
            </Stack>
            {error && <Alert severity="error">{error}</Alert>}
          </Stack>
        </>
      }
      actions={
        <SC.ControlButton variant="contained" onClick={handleSubmit(onSubmit)}>
          {t('Change Password')}
        </SC.ControlButton>
      }
    />
  );
};
