import { useTranslation } from 'react-i18next';

export const useAppTranslation = () => {
  const { i18n, t } = useTranslation();
  return {
    i18n,
    /** @type {(key: import("../types/i18n.types").TranslationKeys) => string }} */
    t: key => t(key),
  };
};
