import React from 'react';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { Box } from '@mui/material';
import { TermsEn } from 'components/TermsEn';
import { TermsSv } from 'components/TermsSv';
import * as SC from 'SC';

const getTerms = lang => {
  switch (lang) {
    case 'en':
      return <TermsEn />;
    case 'sv':
      return <TermsSv />;
    default:
      return <TermsEn />;
  }
};

export const TermsPage = () => {
  const {
    t,
    i18n: { language },
  } = useAppTranslation();

  return (
    <Box height={'100%'} overflow={'auto'} padding={'0 40px 0 30px'}>
      <SC.AuthTitle sx={{ ...SC.AuthTitleSx }}>
        {t('Terms of Service and Privacy Policy')}
      </SC.AuthTitle>
      {getTerms(language)}
      <Box sx={{ height: '20vh' }} />
    </Box>
  );
};
