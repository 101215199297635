import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export const ModalSpinner = ({ open }) => {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        backgroundColor: 'rgb(0,0,0,0)',
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
      open={open}
    >
      <CircularProgress />
    </Backdrop>
  );
};
