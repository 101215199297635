import { httpService } from './httpService';

/**
 *
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

/**
 * @typedef {import('types/commonTypes').Auth} Auth
 * @param {{ username: string; password: string; lang: string; }} props
 * @returns  {HttpResult<Auth>}
 */
export const addUser = async ({ username, password, lang }) =>
  httpService({
    url: `/auth/register/${lang}`,
    options: {
      method: 'POST',
      data: {
        username,
        password,
      },
    },
  });

/**
 * @param {{ username: string; password: string; }} props
 * @returns  {HttpResult<Auth>}
 */
export const login = async ({ username, password }) =>
  httpService({
    url: '/auth/login',
    options: {
      method: 'POST',
      data: {
        username,
        password,
      },
    },
  });

/**
 * @param {string} token
 * @returns  {HttpResult<Auth>}
 */
export const confirmRegistration = async token =>
  httpService({
    url: '/auth/confirm_registration',
    options: {
      method: 'POST',
      data: {
        token,
      },
    },
  });

/**
 * @param {Auth} props
 * @returns  {HttpResult<Auth>}
 */
export const updateAccount = async ({ fullName, password }) =>
  httpService({
    url: '/auth/update_account',
    options: {
      method: 'POST',
      data: {
        fullname: fullName,
        password,
      },
    },
  });

/**
 * @param {string} token
 * @returns  {HttpResult<Auth>}
 */
export const refresh = async token =>
  httpService({
    url: '/auth/refresh',
    options: {
      method: 'POST',
    },
    token,
  });

/**
 * @returns  {HttpResult<string>}
 */
export const logout = async () =>
  httpService({
    url: '/auth/logout',
    options: {
      method: 'POST',
    },
  });

/**
 * @param {{ token: string; }} props
 * @returns  {HttpResult<string>}
 */
export const reissuePasswordChangeToken = async ({ token }) =>
  httpService({
    url: '/auth/reissue_password_change_token',
    options: {
      method: 'POST',
      data: {
        token,
      },
    },
  });

/**
 * @param {{ token: string; }} props
 * @returns  {HttpResult<string>}
 */
export const reissueEmailChangeToken = async ({ token }) =>
  httpService({
    url: '/auth/reissue_email_change_token',
    options: {
      method: 'POST',
      data: {
        token,
      },
    },
  });

/**
 * @param {{ username: string; lang?: string; }} props
 * @returns  {HttpResult<string>}
 */
export const sendResetPasswordLink = async ({ username, lang = 'en' }) =>
  httpService({
    url: '/auth/password_reset_request',
    options: {
      method: 'POST',
      data: {
        username,
        lang,
      },
    },
  });

/**
 * @returns  {HttpResult<any>}
 */
export const sendChangeEmailLink = async ({ lang }) =>
  httpService({
    url: `/auth/email_change_request/${lang}`,
    options: {
      method: 'GET',
    },
  });

/**
 * @param {{ oldPassword: string; newPassword: string; }} props
 * @returns  {HttpResult<string>}
 */
export const changePassword = async ({ oldPassword, newPassword }) =>
  httpService({
    url: '/auth/change_password',
    options: {
      method: 'POST',
      data: {
        oldPassword,
        newPassword,
      },
    },
  });

/**
 * @param {{ token: string; newPassword: string; }} props
 * @returns  {HttpResult<Auth>}
 */
export const changePasswordByTokenAndLogin = async ({ token, newPassword }) =>
  httpService({
    url: '/auth/change_password_by_token',
    options: {
      method: 'POST',
      data: {
        token,
        newPassword,
      },
    },
  });

/**
 * @param {{ token: string; email: string; }} props
 * @returns  {HttpResult<Auth>}
 */
export const changeEmailByTokenAndLogin = async ({ token, email }) =>
  httpService({
    url: '/auth/change_email_by_token',
    options: {
      method: 'POST',
      data: {
        token,
        email,
      },
    },
  });

/**
 * @param {{ password: string; }} props
 * @returns  {HttpResult<string>}
 */
export const deleteAccount = async ({ password }) =>
  httpService({
    url: '/auth/delete_account',
    options: {
      method: 'POST',
      data: {
        password,
      },
    },
  });
