import React, { useContext } from 'react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { GlobalContext } from 'store/globalContext';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Confirmation } from 'components/Confirmation';
import { ErrorDialog } from 'components/ErrorDialog';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { LanguageSelector } from './LanguageSelector';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import PytronicLogo from 'assets/img/pytronic_logo_bw.png';
import * as SC from './CommonLayout.style';
import * as UI from 'UI';
import { MenuLink, menuLinkSx } from './MenuLink';
import { ModalSpinner } from './ModalSpinner';
import { CookieConfirmation } from 'components/CookieConfirmation';
import { WarningDialog } from './WarningDialog';
import { customColors } from 'models/customColors';
import { logoutAction } from 'store/reducers/userReducer';

export const CommonLayout = () => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const spinner = useAppSelector(state => state.spinner);
  const status = useAppSelector(state => state.user?.status);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isHeaderVisible = !pathname.startsWith('/app_view');
  const { openConfirmationWithCallback } = useContext(GlobalContext);

  const logout = () => {
    navigate(`/`);
    dispatch(logoutAction());
  };

  const handleLogoutWithCallback = () =>
    openConfirmationWithCallback({
      title: 'Confirmation needed',
      message: 'Do you really want to log out?',
      callbackFn: logout,
      buttonLabels: [t('Cancel'), t('Logout')],
      closeIcon: false,
    });

  const getPageHeaderText = pathname => {
    if (pathname === '/') return t('Home');
    if (pathname === '/my_profile') return t('My Account');
    if (pathname === '/terms') return t('Privacy Policy');
    if (pathname === '/services') return t('Services');
    if (pathname.startsWith('/details')) return t('Device Details');
    return '';
  };

  return (
    <SC.Container>
      <SC.UpperBox />
      {isHeaderVisible && (
        <SC.ContentWrapper>
          <ModalSpinner open={spinner} />
          <SC.MenuBar>
            <Stack direction={'row'} alignItems={'center'}>
              <Link to="https://pytronic.com">
                <SC.Logo src={PytronicLogo} alt="pytronic logo" />
              </Link>
              <SC.PageHeader sx={SC.PageHeaderSx}>
                {getPageHeaderText(pathname)}
              </SC.PageHeader>
            </Stack>
            <Stack
              direction={'row'}
              justifyContent={'flex-end'}
              alignItems={'center'}
            >
              {status !== 'PASS_SET_REQUIRED' && (
                <>
                  <MenuLink
                    label={t('Home')}
                    to={'/'}
                    pathname={pathname}
                    StartIcon={UI.HomeIcon}
                  />
                  <MenuLink
                    label={t('My Account')}
                    to={'/my_profile'}
                    pathname={pathname}
                    StartIcon={UI.AccountIcon}
                  />
                  <MenuLink
                    label={t('Services')}
                    to={'/services'}
                    pathname={pathname}
                    StartIcon={UI.ServicesIcon}
                  />
                  <Button
                    onClick={handleLogoutWithCallback}
                    startIcon={
                      <UI.LogoutIcon
                        color={customColors.menu.notSelected.text}
                      />
                    }
                    sx={menuLinkSx}
                  >
                    <Typography>{t('Logout')}</Typography>
                  </Button>
                </>
              )}
              <LanguageSelector />
            </Stack>
          </SC.MenuBar>
          <Divider />
          <Confirmation />
          <ErrorDialog />
        </SC.ContentWrapper>
      )}
      <CookieConfirmation />
      <WarningDialog />
      <SC.OutletContainer>
        <Outlet />
      </SC.OutletContainer>
      <SC.Footer>Copyright © All rights reserved. 2023 Pytronic</SC.Footer>
    </SC.Container>
  );
};
