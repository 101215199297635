import { useAppDispatch } from 'store/hooks';
import { showError, hideError } from 'store/reducers/errorReducer';
import { showSpinner, hideSpinner } from 'store/reducers/spinnerReducer';
import { statusMessages } from './httpService';
import { logoutAction, restoreAuth } from 'store/reducers/userReducer';
import { localStorageNameEnum } from 'models/constants';

/**
 *
 * @template T
 * @typedef {import('types/commonTypes').HttpResult<T>} HttpResult
 */

export const useApiRequest = () => {
  const dispatch = useAppDispatch();

  /**
   *
   * @param {{apiRequest: (params:any) => HttpResult<any>; params?: any; skipErrorHandling?: boolean;}} props
   * @returns { HttpResult<any> }
   */
  const callApiRequest = async ({
    apiRequest,
    params,
    skipErrorHandling = false,
  }) => {
    if (!skipErrorHandling) {
      dispatch(hideError());
      dispatch(showSpinner());
    }

    const result = await apiRequest(params);
    const error = result?.error;

    if (error === statusMessages[401]) {
      const refreshToken = window.localStorage.getItem(
        localStorageNameEnum.AUTH_REFRESH_TOKEN
      );
      if (refreshToken) {
        dispatch(restoreAuth(refreshToken));
      } else {
        dispatch(logoutAction());
      }
    }

    if (error && !skipErrorHandling) {
      // @ts-ignore
      dispatch(showError(error));
    }

    if (!skipErrorHandling) {
      dispatch(hideSpinner());
    }
    return result;
  };
  return {
    callApiRequest,
  };
};
