import {
  createAction,
  createAsyncThunk,
  createReducer,
} from '@reduxjs/toolkit';
import { showError, hideError } from './errorReducer';
import { localStorageNameEnum } from 'models/constants';
import { showSpinner, hideSpinner } from './spinnerReducer';

/**
 * @template T
 * @typedef {import('types/stateTypes').PayloadPreparator<T>} PayloadPreparator
 */

/** @type {import("types/stateTypes").User} */
export const initialState = {
  email: '',
  fullName: '',
  role: undefined,
  access: undefined,
  refresh: undefined,
  status: undefined,
};

export const getAuth = createAsyncThunk(
  'user/getAuth',
  async (
    /** @type {{ username: string; password: string;}} */ credentials,
    thunkAPI
  ) => {
    thunkAPI.dispatch(showSpinner());
    thunkAPI.dispatch(hideError());

    try {
      const { username, password } = credentials;
      // @ts-ignore
      const result = await thunkAPI.extra.authApi.login({ username, password });

      if (result?.error) {
        window.localStorage.removeItem(localStorageNameEnum.AUTH_TOKEN);
        thunkAPI.dispatch(showError(result?.error));
      }

      if (result?.value?.access?.token) {
        window.sessionStorage.setItem(
          localStorageNameEnum.AUTH_TOKEN,
          result?.value?.access?.token
        );
        window.localStorage.setItem(
          localStorageNameEnum.AUTH_REFRESH_TOKEN,
          result?.value?.refresh?.token
        );
      }

      return result?.value;
    } catch (e) {
      thunkAPI.dispatch(showError(e.error));
      return Promise.reject(e);
    } finally {
      thunkAPI.dispatch(hideSpinner());
    }
  }
);

export const restoreAuth = createAsyncThunk(
  'user/restoreAuth',
  async (
    /** @type {string} */
    refreshToken,
    thunkAPI
  ) => {
    thunkAPI.dispatch(hideError());

    try {
      // @ts-ignore
      const result = await thunkAPI.extra.authApi.refresh(refreshToken);

      if (result?.error) {
        window.sessionStorage.removeItem(localStorageNameEnum.AUTH_TOKEN);
        window.localStorage.removeItem(localStorageNameEnum.AUTH_REFRESH_TOKEN);
        return Promise.resolve({});
      }

      if (result?.value?.access?.token) {
        window.sessionStorage.setItem(
          localStorageNameEnum.AUTH_TOKEN,
          result?.value?.access?.token
        );
        window.localStorage.setItem(
          localStorageNameEnum.AUTH_REFRESH_TOKEN,
          result?.value?.refresh?.token
        );
      }

      return Promise.resolve(result?.value);
    } catch (e) {
      thunkAPI.dispatch(showError(e.error));
      return Promise.reject(e);
    } finally {
      thunkAPI.dispatch(hideSpinner());
    }
  }
);

export const logoutAction = createAction('user/logout');

const reducer = createReducer(initialState, builder => {
  builder
    .addCase(getAuth.fulfilled, (state, { payload = {} }) => {
      const {
        email = '',
        fullName = '',
        role,
        access,
        refresh,
        status,
      } = payload;
      state.email = email;
      state.fullName = fullName;
      state.role = role;
      state.access = access;
      state.refresh = refresh;
      state.status = status;
    })
    .addCase(logoutAction, () => {
      window.sessionStorage.removeItem(localStorageNameEnum.AUTH_TOKEN);
      window.localStorage.removeItem(localStorageNameEnum.AUTH_REFRESH_TOKEN);
      return initialState;
    })

    .addCase(restoreAuth.fulfilled, (state, { payload = {} }) => {
      const {
        email = '',
        fullName = '',
        role,
        access,
        refresh,
        status,
      } = payload;
      state.email = email;
      state.fullName = fullName;
      state.role = role;
      state.access = access;
      state.refresh = refresh;
      state.status = status;
    });
});

export default reducer;
