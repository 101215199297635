import React from 'react';
import { useAppTranslation } from 'i18n/useAppTranslation';
import { Stack } from '@mui/material';
import * as SC from 'SC';

export const ErrorPage = () => {
  const { t } = useAppTranslation();

  return (
    <Stack
      height={'100%'}
      width={'100%'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <SC.AuthTitle sx={SC.AuthTitleSx}>{t('404 Not Found')}</SC.AuthTitle>
    </Stack>
  );
};
