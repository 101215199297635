import { Box, Button, Stack, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const DialogButton = styled(Button)`
  font-size: 1.2rem;
  min-height: 3rem;
  width: 100%;
`;

export const EmptySpace = styled(Box)`
  height: 1rem;
`;

export const Title = styled(Box)`
  font-weight: 600;
  color: #284a70;
`;

export const TitleSx = {
  height: 40,
  fontSize: {
    lg: 24,
    md: 22,
    sm: 17,
    xs: 17,
  },
};

export const BackButtonContent = styled(Stack)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #7276a8;
`;

export const BackButtonContentSx = {
  fontSize: {
    lg: 23,
    md: 20,
    sm: 17,
    xs: 17,
  },
};

export const Text = styled(Box)`
  color: #284a70;
  font-weight: normal;
  opacity: 0.6;
  font-size: 1.1rem;
`;

export const TextSx = {
  fontSize: {
    lg: 20,
    md: 16,
    sm: 16,
    xs: 16,
  },
};

export const ControlButton = styled(Button)`
  padding: 5px 30px;
  font-size: 1.1rem;
  min-height: 2.8rem;
  width: 100%;
`;

export const AuthFormContainer = styled(Stack)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  color: ${({ theme }) => theme.palette.primary.main};
`;

export const AuthTitle = styled(Title)`
  width: 100%;
  font-size: 3rem;
  text-align: center;
`;

export const AuthTitleSx = {
  height: 40,
  fontSize: {
    lg: 32,
    md: 28,
    sm: 22,
    xs: 22,
  },
};

export const AuthLink = styled(Link)`
  text-decoration: unset;
  color: #5d60dd;
`;
